import React from "react";
import { Button, Typography } from "@mui/material";
import PropTypes from "prop-types";

function ClickWrapButton(props) {
  return (
    <Button size={"large"} onClick={props.onClick} {...props}>
      <Typography style={{ textTransform: "none" }}>
        {props.buttonText}
      </Typography>
    </Button>
  );
}

ClickWrapButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.string,
  color: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
};

export default ClickWrapButton;
