import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import LoginPage from "./pages/login-page";
import ClickWrapDrawer from "./components/click-wrap-drawer";
import CdPocListingScreen from "./pages/dashboard/cd-poc-listing-screen";
import UsersListingScreen from "./pages/dashboard/users-listing-screen";
import { CW_APP_ROUTES } from "./constants/click-wrap-constants";
import CustomerFormScreen from "./pages/dashboard/customer-form-screen";
import ThankYouPage from "./pages/thank-you-page";
import EnvelopeSentPage from "./pages/envelopeSent";

import { useDispatch } from "react-redux";
import * as ActionConstants from "./redux/constants/redux-constants";
import FinalApprovalPage from "./pages/FinalApprovalPage";
import TemplateEditScreen from "./pages/dashboard/template-edit-screen";

function App() {
  const dispatch = useDispatch();
  const [userRole, setUserRole] = useState('')
  const location = useLocation()
  const navigate = useNavigate()

  // eslint-disable-next-line react/prop-types
  function RequireAuth({ children }) {
    return localStorage.getItem("userToken") ? (
      children
    ) : (
      <Navigate to={CW_APP_ROUTES.LOGIN} />
    );
  }

  useEffect(() => {
    if (localStorage.getItem("userToken")) {
      dispatch({
        type: ActionConstants.CURRENT_USER,
        payload: JSON.parse(localStorage.getItem("currentUserInfo")),
      });
    }

  }, []);
  useEffect(() => {
    if (localStorage.getItem('currentUserInfo')) {
      setUserRole(JSON.parse(localStorage.getItem('currentUserInfo')).role)
      console.log("appuserRole", userRole);

    }
  }, [location])
  return (
    <>  
      <Routes>

        <Route
          path={CW_APP_ROUTES.CUSTOMER_FORM}
          element={<CustomerFormScreen />}
        />

        <Route path={CW_APP_ROUTES.LOGIN} element={<LoginPage />} />
        <Route
          path={CW_APP_ROUTES.FINAL_APPROVAL_PAGE}
          element={<FinalApprovalPage />}
        />
        <Route path={CW_APP_ROUTES.THANK_YOU_FORM} element={<ThankYouPage />} />
        <Route path={CW_APP_ROUTES.ENVELOP_SENT_FORM} element={<EnvelopeSentPage />} />


        <Route
          path={CW_APP_ROUTES.CLIENTS}
          element={
            <RequireAuth>
              <ClickWrapDrawer component={<CdPocListingScreen />} />
            </RequireAuth>
          }
        />

        <Route
          path={CW_APP_ROUTES.USERS}
          element={
            
              <RequireAuth>
                <ClickWrapDrawer component={<UsersListingScreen />} />
              </RequireAuth>
              
          }
        />
        <Route
          path={CW_APP_ROUTES.EDIT_TEMPLATE}
          element={
            userRole == "SUPER_ADMIN" || userRole == "LEGAL_TEAM"?
            <RequireAuth>
              <ClickWrapDrawer component={<TemplateEditScreen />} />
            </RequireAuth>: (
                <Navigate to={"/"} />
              )
          }
        />
      </Routes>
    </>
  );
}

export default App;
