import ClickWrapServices from "../services/click-wrap-services";
import { showNotification } from "../../utilities/common-utility-functions";
import { notificationType } from "../../constants/color-constant";
import { cwMessages } from "../../constants/message-constants";

export const updateClickWrap = (i, j, dataObject) => async (dispatch) => {
  try {
    return await ClickWrapServices.updateClickWrap(i, j, dataObject);
  } catch (error) {
    showNotification(error?.response?.data.message, notificationType.ERROR);
  }
};

export const getClickWrapFormStatus = (id) => async () => {
  try {
    return await ClickWrapServices.getClickWrapFormStatus(id);
  } catch (err) {
    showNotification(cwMessages.COMMON_ERROR_MESSAGE, notificationType.ERROR);
  }
};

export const acceptClickWrapTerms = (id) => async () => {
  try {
    return await ClickWrapServices.acceptClickWrapTerms(id);
  } catch (err) {
    showNotification(cwMessages.COMMON_ERROR_MESSAGE, notificationType.ERROR);
  }
};

export const getFileBase64DataAfterClientSubmit = (id) => async () => {
  try {
    return await ClickWrapServices.getFileBase64DataAfterClientSubmit(id);
  } catch (err) {
    showNotification(cwMessages.COMMON_ERROR_MESSAGE, notificationType.ERROR);
  }
};

export const postSignedDocument = (id, body) => async () => {
  try {
    return await ClickWrapServices.postSignedDocument(id, body);
  } catch (err) {
    showNotification(cwMessages.COMMON_ERROR_MESSAGE, notificationType.ERROR);
  }
};

// export const postSignedDocument = (id, body) => async () => {
//   try {
//     return await ClickWrapServices.postSignedDocument(id, body);
//   } catch (err) {
//     showNotification(cwMessages.COMMON_ERROR_MESSAGE, notificationType.ERROR);
//   }
// };

export const getClientSignedInformation = (objectId) => async () => {
  try {
    return await ClickWrapServices.getClientSignedInformation(objectId);
  } catch (err) {
    showNotification(cwMessages.COMMON_ERROR_MESSAGE, notificationType.ERROR);
  }
};

export const getClickWrapDataFromCDPOc = (objectId) => async () => {
  try {
    return await ClickWrapServices.getClickWrapDataFromCDPOc(objectId);
  } catch (err) {
    showNotification(cwMessages.COMMON_ERROR_MESSAGE, notificationType.ERROR);
  }
};
