import { REACT_APP_DS_API_CORS_PROXIES } from "../../constants/click-wrap-constants";
// import OAuthImplicit from "../redux/services/oauth-implimentation";

const oauthResponseHtml = "oauthResponse.html";
const expirationBuffer = 10 * 60;
const sdkString = "codeEg_react";
const urlFrag = "restapi/v2.1";
const oauthState = "oauthState";

class OAuthImplicit {
  static generateId(len = 40) {
    const arr = new Uint8Array((len || 40) / 2);
    function dec2hex(dec) {
      return `0${dec.toString(16)}`.substr(-2);
    }
    window.crypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join("");
  }

  constructor(app) {
    this.app = app;
    this.oauthWindow = null; // only used for new tab auth
  }

  async receiveHash(hash) {
    const accessTokenFound = hash && hash.substring(0, 14) === "#access_token=";
    console.log(accessTokenFound);
    if (!accessTokenFound) {
      this.startLogin();
      //return;
    }
    const hashRegex = /[^#.\-&=_a-zA-Z0-9]/;
    if (hash.search(hashRegex) !== -1) {
      console.error(`Potential XSS attack via fragment (#) value: ${hash}`);
      return;
    }

    const oauthStateValue = localStorage.getItem(oauthState);
    const regex =
      /(#access_token=)(.*)(&expires_in=)(.*)(&token_type=)(.*)(&state=)(.*)/;
    const results = regex.exec(hash);
    console.log("results");
    console.log(results);
    const accessToken = results[2];
    const expiresIn = results[4];
    const incomingState = results[8];
    const stateOk = incomingState === oauthStateValue;
    if (!stateOk) {
      return;
    }
    console.log(window.history);
    if (process.env.REACT_APP_DS_REDIRECT_AUTHENTICATION) {
      window.history.replaceState(null, "", process.env.REACT_APP_DS_APP_URL);
    } else {
      if (this.oauthWindow) {
        this.oauthWindow.close();
      }
    }

    const expires = new Date();
    expires.setTime(expires.getTime() + (expiresIn - expirationBuffer) * 1000);
    this.accessToken = accessToken;
    console.log("Before Fetch User Info");
    const userInfo = await this.fetchUserInfo();
    const defaultAccountArray = userInfo.accounts.filter(
      (acc) => acc.is_default
    );
    const defaultAccount =
      defaultAccountArray.length > 0 && defaultAccountArray[0];
    if (!defaultAccount) {
      const msg = `Problem: the user does not have a default account. Contact DocuSign Customer Service to fix.`;
      console.error(msg);
      return;
    }

    const baseUri = defaultAccount.base_uri;
    if (!baseUri) {
      const msg = `Problem: no proxy for ${defaultAccount.base_uri}.`;
      console.error(msg);
      return;
    }

    const externalAccountId = await this.getExternalAccountId(
      defaultAccount.account_id,
      baseUri
    );
    
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("externalAccountId", externalAccountId);
    localStorage.setItem("expires", expires);
    // localStorage.setItem("name", "Sneh Shah");
    // localStorage.setItem("email", "sneh@tatvic.com");
    localStorage.setItem("accountId", defaultAccount.account_id);
    localStorage.setItem("accountName", defaultAccount.account_name);
    localStorage.setItem("baseUri", baseUri);
  }

  startLogin() {
    const oauthStateValue = OAuthImplicit.generateId();
    localStorage.setItem(oauthState, oauthStateValue);
    let redirectUrl;
    if (process.env.REACT_APP_DS_REDIRECT_AUTHENTICATION) {
      redirectUrl = process.env.REACT_APP_DS_APP_URL;
    } else {
      redirectUrl = `${process.env.REACT_APP_DS_APP_URL}/${oauthResponseHtml}`;
    }

    const url =
      `${process.env.REACT_APP_DS_IDP}/oauth/auth?` +
      `response_type=token&` +
      `scope=${process.env.REACT_APP_IMPLICIT_SCOPES}&` +
      `client_id=${process.env.REACT_APP_DS_CLIENT_ID}&` +
      `state=${oauthStateValue}&` +
      `redirect_uri=${encodeURIComponent(redirectUrl)}`;

    if (process.env.REACT_APP_DS_REDIRECT_AUTHENTICATION) {
      window.location = url;
    } else {
      this.oauthWindow = window.open(url);
    }
  }

  logout() {
    window.location =
      `${process.env.REACT_APP_DS_IDP}/logout?` +
      `response_type=token&` +
      `scope=${process.env.REACT_APP_IMPLICIT_SCOPES}&` +
      `client_id=${process.env.REACT_APP_DS_CLIENT_ID}&` +
      `redirect_uri=${encodeURIComponent(process.env.REACT_APP_DS_APP_URL)}&` +
      `response_mode=logout_redirect`;
  }

  async fetchUserInfo() {
    let userInfoResponse;
    try {
      userInfoResponse = await fetch(
        `${process.env.REACT_APP_DS_IDP}/oauth/userinfo`,
        {
          headers: new Headers({
            Authorization: `Bearer ${this.accessToken}`,
            Accept: `application/json`,
            "X-DocuSign-SDK": sdkString,
          }),
        }
      );
    } catch (e) {
      const msg = `Problem while completing login.\nPlease retry.\nError: ${e.toString()}`;
      console.error(msg);
      return null;
    }
    if (!userInfoResponse || !userInfoResponse.ok) {
      const msg = `Problem while completing login.\nPlease retry.\nError: ${userInfoResponse.statusText}`;
      console.error(msg);
      return null;
    }
    return await userInfoResponse.json();
  }

  async getExternalAccountId(accountId, baseUri) {
    try {
      const url =
        REACT_APP_DS_API_CORS_PROXIES[baseUri] +
        `/` +
        `${urlFrag}/accounts/${accountId}`;
      const response = await fetch(url, {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${this.accessToken}`,
          Accept: `application/json`,
          "X-DocuSign-SDK": sdkString,
          "Access-Control-Allow-Origin": baseUri,
          "Access-Control-Allow-Credentials": baseUri,
        }),
      });
      const data = response && response.ok && (await response.json());
      return data.externalAccountId;
    } catch (e) {
      return null;
    }
  }
}

export default OAuthImplicit;
