import React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";
import PropTypes from "prop-types";

function ClickWrapCheckBoxGroup({ label, options, onChange, name }) {
  return (
    <FormControl sx={{ width: "100%" }}>
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup>
        {options.map((elementData) => {
          return (
            <FormControlLabel
              key={elementData.label}
              control={
                <Checkbox
                  id={name}
                  name={elementData.name}
                  onChange={onChange}
                />
              }
              label={elementData.label}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
}

ClickWrapCheckBoxGroup.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  name: PropTypes.string,
};

export default ClickWrapCheckBoxGroup;
