export const CW_TYPE_OF_ENTITY = [
  {
    name: "Company",
    label: "COMPANY",
  },
  {
    name: "Partnership Firm",
    label: "PARTNERSHIP_FIRM",
  },
  {
    name: "Individual",
    label: "INDIVIDUAL",
  },
];

export const CW_USER_ROLE_TYPE = [
  {
    name: "Legal Team",
    label: "LEGAL_TEAM",
  },
  {
    name: "Finance Team",
    label: "FINANCE_TEAM",
  },
  {
    name: "CD Team",
    label: "CD_TEAM",
  },
  {
    name: "AM Team",
    label: "AM_TEAM",
  },
];

export const CW_CURRENCY_OPTED_FOR_BILLING = [
  {
    name: "INR",
    label: "INR",
  },
  {
    name: "USD",
    label: "USD",
  },
  {
    name: "SGD",
    label: "SGD",
  },
  {
    name: "Dirham",
    label: "DIRHAM",
  },
  {
    name: "Euro",
    label: "EURO",
  },
  {
    name: "Other",
    label: "OTHER",
  },
];

export const CW_COMMON_QUESTION = [
  {
    name: "Yes",
    label: "YES",
  },
  {
    name: "No",
    label: "NO",
  },
];

export const CW_SUPPORT_SERVICE_TYPE = [
  {
    name: "NO",
    label: "No",
  },
  {
    name: "RICH_MEDIA_CREATIVES",
    label: "Rich Media Creatives",
  },
  {
    name: "CAMPAIGNS",
    label: "Campaigns",
  },
  {
    name: "OPTIMIZATION_RECOMMENDATION",
    label: "Optimization Recommendation",
  },
  {
    name: "STRATEGIC_DISCUSSION",
    label: "Strategic Discussion",
  },
  {
    name: "AUDIENCE_PROFILE_ANALYSIS",
    label: "Audience Profile Analysis",
  },
  {
    name: "ACTIVITY_BASED_AUDIENCE_BUILDER",
    label: "Activity Based Audience Builder",
  },
  {
    name: "AUDIENCE_BASED_FREQUENCY_CAPS",
    label: "Audience Based Frequency Caps",
  },
];

export const CW_COMPONENT_TYPE = {
  TEXT_BOX: "TEXT_BOX",
  RADIO: "RADIO",
  DROP_DOWN: "DROP_DOWN",
  CHECK_BOX_GROUP: "CHECK_BOX_GROUP",
  FILE_UPLOAD_COMPONENT: "FILE_UPLOAD_COMPONENT",
  DATE_PICKER: "DATE_PICKER",
};

export const CW_ENTITY_TYPE = {
  COMPANY: "COMPANY",
  PARTNERSHIP_FIRM: "PARTNERSHIP_FIRM",
  INDIVIDUAL: "INDIVIDUAL",
};

export const CW_APP_ROUTES = {
  USERS: "/users",
  CLIENTS: "/",
  LOGIN: "/login",
  CUSTOMER_FORM: "/customerForm",
  THANK_YOU_FORM: "/thankYouPage",
  ENVELOP_SENT_FORM: "/envelopeSent",
  FINAL_APPROVAL_PAGE: "/finalApprovalPage",
  EDIT_TEMPLATE: "/editTemplate"

};

export const CW_ORGANISATION_TYPE = [
  {
    label: "TATVIC_ANALYTICS_PVT_LTD",
    name: "Tatvic Analytics Private Limited",
  },
  {
    label: "TATVIC_MEDIA_CONSULTING_PVT_LTD",
    name: "Tatvic Media Consulting Private Limited",
  },
  {
    label: "TATVIC_ANALYTICS_LLC",
    name: "Tatvic Analytics LLC",
  },
];

export const CW_SERVICES = {
  DBM: "DBM",
  DCM_DRM: "DCM_DRM",
  DBM_DCM_DRM: "DBM_DCM_DRM",
  GCP: "GCP",
  G_SUITE: "G_SUITE",
  DCM: "DCM",
  DBM_DCM: "DBM_DCM",
  GA_4:"GA_4",
  CRO:"CRO"
};

export const CW_TYPES_OF_SERVICES_DATA_MAPPING = {
  DBM: "Display and Video 360 Service",
  DCM_DRM: "Campaign Management and Search Ads 360 Services",
  DBM_DCM_DRM: "GMP Advertising Agreement",
  GCP: "GCP",
  G_SUITE: "G-Suite",
  DCM: "Campaign Manager Ad Service",
  DBM_DCM: "DV360 and Campaign",
  GA_4: "GA_4",
  CRO:"CRO"
};

export const CW_TYPES_OF_SERVICES = [
  // {
  //   label: "DBM",
  //   name: "Display and Video 360 Service",
  // },
  // {
  //   label: "DCM_DRM",
  //   name: "Campaign Management and Search Ads 360 Services",
  // },
  {
    label: "DBM_DCM_DRM",
    name: "GMP Advertising Agreement",
  },
  {
    label: "GCP",
    name: "GCP",
  },
  {
    label: "GA_4",
    name: "GA 4",
  },
  {
    label:"CRO",
    name:"CRO"
  },
  // {
  //   label: "DCM",
  //   name: "Campaign Manager Ad Service",
  // },
  // {
  //   label: "DBM_DCM",
  //   name: "DV360 and Campaign",
  // },
];

export const clickWrapUserRole = {
  SUPER_ADMIN: "Admin",
  LEGAL_TEAM: "Legal Team",
  FINANCE_TEAM: "Finance Team",
  CD_TEAM: "CD Team",
  AM_TEAM: "AM Team"

};

export const tablePaginationStaticList = [20, 40, 60];

export const REACT_APP_DS_API_CORS_PROXIES = {
  // "https://na2.docusign.net": "https://cors-anywhere.herokuapp.com/https://na2.docusign.net",
  // "https://na3.docusign.net": "https://cors-anywhere.herokuapp.com/https://na3.docusign.net",
  // "https://na4.docusign.net": "https://cors-anywhere.herokuapp.com/https://na4.docusign.net",
  // "https://demo.docusign.net": "https://cors-anywhere.herokuapp.com/https://clickwrap.tatvic.com",
  // "https://www.docusign.net": "https://cors-anywhere.herokuapp.com/https://www.docusign.net",

  "https://na2.docusign.net": "https://clickwrap.tatvic.com",
  "https://na3.docusign.net": "https://clickwrap.tatvic.com",
  "https://na4.docusign.net": "https://clickwrap.tatvic.com",
  "https://demo.docusign.net": "https://clickwrap.tatvic.com",
  "https://www.docusign.net": "https://clickwrap.tatvic.com",
};

export const CW_NATIONALITY_OPTIONS = [
  {
    name: "National",
    label: "NATIONAL",
  },
  {
    name: "Foreign",
    label: "FOREIGN",
  },
];


export const CW_SUPPORT_HOURS_OPTIONS = [
  {
    name: "Yes",
    label: "YES",
  },
  {
    name: "No",
    label: "NO",
  },
];


export const CW_ONE_TIME_TRAINING = [
  {
    name: "Yes",
    label: "YES",
  },
  {
    name: "No",
    label: "NO",
  },
];
