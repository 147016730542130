import AxiosInstance from "../AxiosInstance";

class CdPocService {
  getAllCDPocServices() {
    return AxiosInstance.get("/api/v1/cd-poc");
  }

  addCDPoC(data) {
    return AxiosInstance.post("/api/v1/cd-poc", data);
  }

  approveOrReject(id, data) {
    return AxiosInstance.post("/api/v1/cd-poc/approve-reject/" + id, data);
  }
}

export default new CdPocService();
