import React, { useEffect, useState } from "react";
import ClickWrapDialog from "../../components/click-wrap-dialog";
import CustomerForm from "../../forms/customer-form";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import ClickWrapButton from "../../components/click-wrap-button";
import {
  addValuesInDataObject,
  showNotification,
  useQuery,
} from "../../utilities/common-utility-functions";
import { useDispatch } from "react-redux";
import {
  acceptClickWrapTerms,
  getClickWrapFormStatus,
  getFileBase64DataAfterClientSubmit,
  updateClickWrap,
} from "../../redux/actions/click-wrap-actions";
import ThankYouPage from "../thank-you-page";
import OAuthImplicit from "../../redux/services/oauth-implimentation";
import { CUSTOMER_FORM_STRUCTURE_LIST } from "../../constants/click-wrap-form-constants";
import { notificationType } from "../../constants/color-constant";

// eslint-disable-next-line react/prop-types
function SubCustomerCheck({ toggleTermsDialog }) {
  const dispatch = useDispatch();
  const query = useQuery();

  const onChangeTermsBox = (element) => {
    if (element.target.checked) {
      dispatch(acceptClickWrapTerms(query.get("j"))).then(() => {
        toggleTermsDialog();
      });
    }
  };

  return (
    <div>
      <Typography style={{ fontSize: 12 }}>
        <strong>INSTRUCTIONS:</strong>{" "}
        <i>
          CAREFULLY READ THE AGREEMENT. IT CONTAINS VERY IMPORTANT INFORMATION
          ABOUT THE RIGHTS AND OBLIGATIONS OF THE PARTIES, AS WELL AS
          LIMITATIONS AND EXCLUSIONS THAT MAY APPLY TO YOU. BY CLICKING ON THE
          ACCEPT BUTTON, YOU ARE CONSENTING TO BE BOUND BY AND ARE BECOMING A
          PARTY TO THIS AGREEMENT IN CASE AN AUTHORISED SIGNATORY IS ENTERING IN
          TO THE AGREEMENT ON BEHALF OF A COMPANY(CUSTOMER/CLIENT), THEN THE
          COMPANY SHALL BE LIABLE FOR ALL ACTS AND OMISSIONS OF the AUTHORISED
          SIGNATORY. ANY PERSON WHO ENTERS IN TO AGREEMENT WITH SERVICE PROVIDER
          USING COMPANY’S CREDENTIALS SHALL BE DEEMED TO BE AUTHORISED
          SIGNATORY. NO TERM INCLUDED IN ANY CONFIRMATION, ACCEPTANCE, PURCHASE
          ORDER, OR ANY OTHER SIMILAR DOCUMENT ISSUED BY YOU/COMPANY IN
          CONNECTION WITH AN AGREEMENT WILL APPLY TO THE AGREEMENT OR HAVE ANY
          FORCE OR EFFECT. IF YOU DO NOT AGREE TO ALL OF THE TERMS OF THIS
          AGREEMENT THEN DON’T PROCEED FURTHER HEREINAFTER.
        </i>
        <Divider style={{ marginTop: 5, marginBottom: 5 }} />I hereby represent
        that:
        <ul>
          <li>
            You are capable and competent of entering into this agreement and
            above the age of 18 years. You hereby give your wilful consent to
            entering into a binding legal relationship with the Service Provider
            in respect of the Agreement to use Service(s)/Product(s) of Service
            Providers as described in this electronic Agreement. You acknowledge
            that the performance of obligations contained in the agreement by
            you as customer/client as well as by Service provider is something
            which is not impossible to perform.
          </li>
          <li>
            The terms of service are specifically conveyed to you which is read
            and fully understood by you by bringing in to your notice without
            concealing anything and in the language understandable by you.
          </li>
          <li>
            You are entering into this agreement with your own will/consent
            there is no pressure, undue influence or coercion upon you to enter
            in to this agreement.
          </li>
          <li>
            You agree that the terms of the agreement are mutual, fair and
            reasonable and You do have equal bargaining power as of the Service
            Provider.
          </li>
          <li>
            You agree to go through, read and fully understand the terms of the
            agreement and adhere to the links as mentioned in the agreement as
            modified from time to time.
          </li>
          <li>
            You expressly agree that the terms and conditions of the agreement
            are not unfair or unreasonable and You have been given the
            opportunity to disagree to the terms and conditions of this
            electronic agreement and opt out of this page/terms.
          </li>
          <li>
            You accept and agree that the agreement to purchase
            Service(s)/Product(s) of the company can be executed in electronic
            format and it shall be considered as legally binding valid contract
            in eyes of law which can be enforceable in the court of law. You
            expressly agree that you have downloaded, read and understood the
            terms of the agreement.
          </li>
          <li>
            You are clicking the “I agree and Accept” button after reviewing all
            the terms and conditions of the Service(s)/Product(s) which is
            clearly visible and noticeable to you as a customer. The terms of
            the agreement are available to download and fully noticeable.
          </li>
          <li>
            You agree to be bound by the above-mentioned terms and conditions on
            your behalf and on behalf of Organization and you represent and
            warrant that you have the authority to bind the Organization to
            these Terms. Here, &quot;you&quot; and &quot;your&quot; refers to
            &quot;you&quot; and the Organization you are representing.
          </li>
          {/* <li>
            I agree to be bound by the above mentioned terms and conditions on
            my behalf or the behalf of Organization and I represent and warrant
            that I have the authority to bind the Organization to these Terms.
            Here, &quot;you&quot; and &quot;your&quot; refers to me and the
            Organization I am representing.
          </li> */}
        </ul>
      </Typography>
      <FormGroup sx={{ mt: 1 }}>
        <Divider />
        <FormControlLabel
          control={<Checkbox onChange={(e) => onChangeTermsBox(e)} />}
          label={`I have read and agreed to the Terms and conditions.`}
        />
      </FormGroup>
    </div>
  );
}

function CustomerFormScreen() {
  const query = useQuery();
  const dispatch = useDispatch();

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isOpenTermsDialog, setIsOpenTermsDialog] = useState(true);
  const [selectedEntityType, setSelectedEntityType] = useState("");

  const [dataObject, setDataObject] = useState({});
  const [messageObject, setMessageObject] = useState({});
  const [nationality, setNationality] = useState(null)

  const [loadedAPIData, setLoadedAPIData] = useState({});

  const oAuthImplicit = new OAuthImplicit();

  const onChangeComponent = async (type, e) => {
    setDataObject(await addValuesInDataObject(dataObject, type, e));
  };

  const toggleTermsDialog = () => {
    setIsOpenTermsDialog(!isOpenTermsDialog);
  };

  useEffect(() => {
    localStorage.setItem("clickWrapID", query.get("j"));

    dispatch(getClickWrapFormStatus(query.get("j"))).then((result) => {
      if (result && result.status === 200) {
        setIsOpenTermsDialog(!result.data.data.isAccepted);
        setSelectedEntityType(result.data.data.entityType);
        setNationality(result.data.data.nationality);
        localStorage.setItem("filePDF", result.data.data.unsigned_pdf);
        if (result.data.data.isSubmitted) {
          setIsFormSubmitted(true);
          setMessageObject({
            heading: "Thank You",
            description:
              "You've already submitted a form, Please wait for 24 Hrs.",
          });
        }

        const different =
          Math.abs(new Date() - new Date(result.data.data.createrdAt)) /
          3600000;

        if (different > 48) {
          setIsFormSubmitted(true);
          setMessageObject({
            heading: "Oops!!",
            description: "Link has expired, Please contact administrator.",
          });
        }

        setLoadedAPIData(result?.data?.data);
        setIsDataLoaded(true);
      } else {
        setIsDataLoaded(true);
      }
    });
  }, []);

  function waitForMilliSec(timeInSecond) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve("");
      }, timeInSecond);
    });
  }

  const onClickSubmit = async () => {

    await localStorage.setItem("clickWrapID", query.get("j"));
    let requiredFields = CUSTOMER_FORM_STRUCTURE_LIST.filter(

      (e) => e.showIn.length === 0 || e.showIn.includes(selectedEntityType)
    );

    requiredFields = requiredFields.filter(items => {
      if (items.name === 'isRegisteredGSTEntity' && nationality !== 'NATIONAL') {
        return false;
      } else {
        if (items.name === "gstRegisteredNumber" || items.name === "clientEngagedIn") {
          if (nationality === 'NATIONAL' && (dataObject?.isRegisteredGSTEntity && dataObject.isRegisteredGSTEntity === "YES")) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }


    })

    delete dataObject.disabled
    if (dataObject.isRegisteredGSTEntity == "NO") {
      delete dataObject.gstRegisteredNumber
      delete dataObject.clientEngagedIn
    }

    console.log("requiredFields", requiredFields);
    console.log("key", Object.keys(dataObject).length, dataObject);

    if (Object.keys(dataObject).length === requiredFields.length) {
      await dispatch(
        updateClickWrap(query.get("i"), query.get("j"), dataObject)
      ).then(async (result) => {
        if (result && result.status === 200) {
          // await waitForMilliSec(3000);
          // await dispatch(
          //   getFileBase64DataAfterClientSubmit(result.data.data._id)
          // ).then((documentResult) => {
          //   localStorage.setItem(
          //     "filePDF",
          //     documentResult.data.data[0].unsigned_pdf
          //   );
          //   // oAuthImplicit.startLogin();
          // });
          setIsFormSubmitted(true);
          setMessageObject({
            heading: "Processing",
            description: "We are processing, Please wait for few minutes.",
          });
        }
      });
    } else {
      /* Nishidh comment
            const finalList = requiredFields
              .filter((e) => !Object.keys(dataObject).includes(e.name))
              .map((element) => {
                return element.label ? element.label : element.disabledOptionCaption;
              })
              .filter((e) => e !== undefined);
              console.log("finalList",finalList);
              */
      var dataObjectNames = Object.keys(dataObject)
      var finalList = []
      var requireFieldNames = requiredFields.map(items => {
        return items.name || "govtApprovedIDProofDocument"
      })

      var nameNotInclude = requireFieldNames.filter(items => {
        if (!dataObjectNames.includes(items)) {
          return items
        }
      })
      requiredFields.map(items => {
        if (nameNotInclude.includes(items.name)) {
          finalList.push(items.label);
        }
      })
      if (nameNotInclude.includes("govtApprovedIDProofDocument")) {
        finalList.push("Upload document in Pdf format")
      }
      if (dataObject['tanNumber']) {
        if (!/[A-Za-z]{4}[0-9]{5}[A-Za-z]{1}/.test(dataObject['tanNumber'])) {
          if (finalList.includes("Provide your TAN Number")) {
            finalList[finalList.indexOf("Provide your TAN Number")] = "Enter valid TAN Number"

          } else {
            finalList.push("Enter valid TAN Number")
          }
        }
      }

      console.log("finalList", finalList);
      showNotification(
        finalList?.toString(),
        notificationType.ERROR
      );

    }
  };

  if (!isDataLoaded) {
    return null;
  }

  return (
    <div>
      {isFormSubmitted ? (
        <ThankYouPage messageObject={messageObject} />
      ) : (
        <>
          <ClickWrapDialog
            dialogTitle={"Terms & Conditions"}
            loadedAPIData={loadedAPIData}
            component={
              <SubCustomerCheck toggleTermsDialog={toggleTermsDialog} />
            }
            isWithoutSaveButton
            isWithoutCancelButton
            isWithoutClose
            onCloseModal={toggleTermsDialog}
            isOpenDialog={isOpenTermsDialog}
          />
          <CustomerForm
            selectedEntityType={selectedEntityType}
            loadedAPIData={loadedAPIData}
            nationality={nationality}
            dataObject={dataObject}
            onChangeComponent={onChangeComponent}
          />
          <Grid
            sx={{ mb: 2 }}
            alignItems={"center"}
            justifyContent={"center"}
            container
          >
            <ClickWrapButton
              variant={"outlined"}
              onClick={onClickSubmit}
              buttonText={"Submit"}
            />
          </Grid>
        </>
      )}
    </div>
  );
}

export default CustomerFormScreen;
