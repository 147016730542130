import {
  CW_COMMON_QUESTION,
  CW_COMPONENT_TYPE,
  CW_CURRENCY_OPTED_FOR_BILLING,
  CW_ENTITY_TYPE,
  CW_NATIONALITY_OPTIONS,
  CW_ORGANISATION_TYPE,
  CW_SERVICES,
  CW_SUPPORT_SERVICE_TYPE,
  CW_TYPE_OF_ENTITY,
  CW_TYPES_OF_SERVICES,
  CW_USER_ROLE_TYPE,
  CW_SUPPORT_HOURS_OPTIONS,
} from "./click-wrap-constants";

export const CUSTOMER_FORM_STRUCTURE_LIST = [
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "soleProprietor",
    label: "Name of Individual",
    placeHolder: "Name of Individual",
    showIn: [
      CW_ENTITY_TYPE.INDIVIDUAL,
      // CW_ENTITY_TYPE.PARTNERSHIP_FIRM,
      // CW_ENTITY_TYPE.COMPANY,
    ],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "clientEmailAddress",
    label: "Email Address",
    placeHolder: "Email Address",
    showIn: [
      CW_ENTITY_TYPE.INDIVIDUAL,
      CW_ENTITY_TYPE.PARTNERSHIP_FIRM,
      CW_ENTITY_TYPE.COMPANY,
    ],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "nameOfLegalEntity",
    label: "Name Of Legal Entity",
    placeHolder: "Name Of Legal Entity",
    showIn: [
      // CW_ENTITY_TYPE.INDIVIDUAL,
      CW_ENTITY_TYPE.PARTNERSHIP_FIRM,
      CW_ENTITY_TYPE.COMPANY,
    ],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "nameOfBusiness",
    label: "Name Of Business",
    placeHolder: "Name Of Business",
    showIn: [
      CW_ENTITY_TYPE.INDIVIDUAL,
      CW_ENTITY_TYPE.PARTNERSHIP_FIRM,
      CW_ENTITY_TYPE.COMPANY,
    ],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "registeredOfficeAddress",
    label: "Registered Office Address",
    placeHolder: "Provide Registered Office Address",
    isWithMultiline: true,
    numberOfRows: 4,
    showIn: [
      // CW_ENTITY_TYPE.INDIVIDUAL,
      CW_ENTITY_TYPE.PARTNERSHIP_FIRM,
      CW_ENTITY_TYPE.COMPANY,
    ],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "registeredOfficeAddress",
    label: "Address of Correspondence",
    placeHolder: "Address of Correspondence",
    isWithMultiline: true,
    numberOfRows: 4,
    showIn: [
      CW_ENTITY_TYPE.INDIVIDUAL,
      // CW_ENTITY_TYPE.PARTNERSHIP_FIRM,
      // CW_ENTITY_TYPE.COMPANY,
    ],
  },
  {
    type: CW_COMPONENT_TYPE.RADIO,
    name: "isYourCorporateOfficeSameAsRegistered",
    legend: "Is your Corporate Office Address same as Registered Office?",
    options: CW_COMMON_QUESTION,
    showIn: [
      // CW_ENTITY_TYPE.INDIVIDUAL,
      // CW_ENTITY_TYPE.PARTNERSHIP_FIRM,
      CW_ENTITY_TYPE.COMPANY,
    ],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "corporateOfficeAddress",
    label: "Corporate Office Address",
    placeHolder: "Provide Corporate Office Address",
    isWithMultiline: true,
    numberOfRows: 4,
    showIn: [
      // CW_ENTITY_TYPE.INDIVIDUAL,
      // CW_ENTITY_TYPE.PARTNERSHIP_FIRM,
      CW_ENTITY_TYPE.COMPANY,
    ],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "corporateIdentificationNumber",
    label: "Corporate Identification Number / Registration Number",
    placeHolder:
      "Provide Corporate Identification Number (CIN) / Registration Number",
    showIn: [CW_ENTITY_TYPE.COMPANY],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "nameOfCountry",
    label: "Name Of Country",
    placeHolder: "Country of residence",
    showIn: [CW_ENTITY_TYPE.COMPANY, CW_ENTITY_TYPE.INDIVIDUAL],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "countryWhenPartnershipDone",
    label: "Country of Partnership",
    placeHolder:
      "Name of the Country in which incorporation of the Partnership is done",
    showIn: [
      // CW_ENTITY_TYPE.INDIVIDUAL,
      CW_ENTITY_TYPE.PARTNERSHIP_FIRM,
      // CW_ENTITY_TYPE.COMPANY,
    ],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "soleProprietor",
    label: "Name of Partner",
    placeHolder: "Name of Partner",
    showIn: [
      // CW_ENTITY_TYPE.INDIVIDUAL,
      CW_ENTITY_TYPE.PARTNERSHIP_FIRM,
      // CW_ENTITY_TYPE.COMPANY,
    ],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "panCard",
    label: "PAN(Permanent Account Number)",
    placeHolder: "Enter PAN Card Number",
    showIn: [
      CW_ENTITY_TYPE.INDIVIDUAL,
      CW_ENTITY_TYPE.PARTNERSHIP_FIRM,
      // CW_ENTITY_TYPE.COMPANY,
    ],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "passportNumber",
    label: "Passport Number",
    placeHolder: "Passport Number (If Foreigner)",
    showIn: [
      CW_ENTITY_TYPE.INDIVIDUAL,
      CW_ENTITY_TYPE.PARTNERSHIP_FIRM,
      // CW_ENTITY_TYPE.COMPANY,
    ],
  },
  // {
  //   type: CW_COMPONENT_TYPE.TEXT_BOX,
  //   name: "drivingLicenseNumber",
  //   label: "Driving License Number",
  //   placeHolder: "Driving License Number",
  //   showIn: [
  //     // CW_ENTITY_TYPE.INDIVIDUAL,
  //     // CW_ENTITY_TYPE.PARTNERSHIP_FIRM,
  //     // CW_ENTITY_TYPE.COMPANY,
  //   ],
  // },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "emailAddressForInvoice",
    label: "Provide Email ID to send the Invoice",
    placeHolder: "Email ID to send the Invoice",
    showIn: [
      CW_ENTITY_TYPE.INDIVIDUAL,
      CW_ENTITY_TYPE.PARTNERSHIP_FIRM,
      CW_ENTITY_TYPE.COMPANY,
    ],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "personOfContactForCorrespondence",
    label: "Name of person for legal notice",
    placeHolder: "Name of person of Contact for legal notice",
    showIn: [
      CW_ENTITY_TYPE.INDIVIDUAL,
      CW_ENTITY_TYPE.PARTNERSHIP_FIRM,
      CW_ENTITY_TYPE.COMPANY,
    ],
  },
  // {
  //   type: CW_COMPONENT_TYPE.TEXT_BOX,
  //   name: "addressWhereAnyCorrespondenceToBeShared",
  //   label: "Email Address of person for legal notice",
  //   placeHolder: "Email Address where any legal correspondence is to be shared",
  //   isWithMultiline: false,
  //   numberOfRows: 4,
  //   showIn: [
  //     CW_ENTITY_TYPE.INDIVIDUAL,
  //     CW_ENTITY_TYPE.PARTNERSHIP_FIRM,
  //     CW_ENTITY_TYPE.COMPANY,
  //   ],
  // },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "emailIdOfCorrespondence",
    label: "Email Address of person for legal notice",
    placeHolder: "Email Address where any legal correspondence is to be shared",
    showIn: [
      CW_ENTITY_TYPE.INDIVIDUAL,
      CW_ENTITY_TYPE.PARTNERSHIP_FIRM,
      CW_ENTITY_TYPE.COMPANY,
    ],
  },
  // {
  //   type: CW_COMPONENT_TYPE.TEXT_BOX,
  //   name: "contactOfCorrespondence",
  //   label: "Contact Number Of Correspondence",
  //   placeHolder: "Contact number of Person of Contact for Correspondence",
  //   showIn: [
  //     CW_ENTITY_TYPE.INDIVIDUAL,
  //     CW_ENTITY_TYPE.PARTNERSHIP_FIRM,
  //     CW_ENTITY_TYPE.COMPANY,
  //   ],
  // },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "nameOfAuthorizedSignatory",
    label: "Authorized Signatory",
    placeHolder: "Name of the Authorized Signatory",
    showIn: [
      // CW_ENTITY_TYPE.INDIVIDUAL,
      CW_ENTITY_TYPE.PARTNERSHIP_FIRM,
      CW_ENTITY_TYPE.COMPANY,
    ],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "designationOfTheAuthorizedSignatory",
    label: "Designation Of Authorized Signatory",
    placeHolder: "Designation Of Authorized Signatory",
    showIn: [
      // CW_ENTITY_TYPE.INDIVIDUAL,
      CW_ENTITY_TYPE.PARTNERSHIP_FIRM,
      CW_ENTITY_TYPE.COMPANY,
    ],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "emailIdOfAuthorizedSignatory",
    label: "Email ID Of the Authorized Signatory",
    placeHolder: "Email ID Of the Authorized Signatory",
    showIn: [
      // CW_ENTITY_TYPE.INDIVIDUAL,
      CW_ENTITY_TYPE.PARTNERSHIP_FIRM,
      CW_ENTITY_TYPE.COMPANY,
    ],
  },

  // {
  //   type: CW_COMPONENT_TYPE.TEXT_BOX,
  //   name: "contactNoOfAuthSignatory",
  //   label: "Contact Number of Authorized Signatory",
  //   placeHolder: "Contact Number of Authorized Signatory",
  //   showIn: [
  //     CW_ENTITY_TYPE.INDIVIDUAL,
  //     CW_ENTITY_TYPE.PARTNERSHIP_FIRM,
  //     CW_ENTITY_TYPE.COMPANY,
  //   ],
  // },
  {
    type: CW_COMPONENT_TYPE.RADIO,
    name: "isRegisteredGSTEntity",
    legend: "Are you GST Registered?",
    options: CW_COMMON_QUESTION,
    showIn: [
      CW_ENTITY_TYPE.INDIVIDUAL,
      CW_ENTITY_TYPE.PARTNERSHIP_FIRM,
      CW_ENTITY_TYPE.COMPANY,
    ],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "gstRegisteredNumber",
    label: "Provide your GST Registration Number (If any)",
    placeHolder: "This should be compulsory only when there is Indian entity",
    showIn: [
      CW_ENTITY_TYPE.INDIVIDUAL,
      CW_ENTITY_TYPE.PARTNERSHIP_FIRM,
      CW_ENTITY_TYPE.COMPANY,
    ],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "clientEngagedIn",
    label: "Provide description of business",
    placeHolder: "Provide description of business",
    showIn: [
      CW_ENTITY_TYPE.INDIVIDUAL,
      CW_ENTITY_TYPE.PARTNERSHIP_FIRM,
      CW_ENTITY_TYPE.COMPANY,
    ],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "tanNumber",
    label: "Provide your TAN Number",
    placeHolder: "Provide your TAN Number",
    showIn: [
      CW_ENTITY_TYPE.INDIVIDUAL,
      CW_ENTITY_TYPE.PARTNERSHIP_FIRM,
      CW_ENTITY_TYPE.COMPANY,
    ],
  },
  // {
  //   type: CW_COMPONENT_TYPE.CHECK_BOX_GROUP,
  //   label: "Select the Additional support services",
  //   name: "additionalSupportServices",
  //   optionList: CW_SUPPORT_SERVICE_TYPE,
  //   showIn: [
  //     CW_ENTITY_TYPE.INDIVIDUAL,
  //     CW_ENTITY_TYPE.PARTNERSHIP_FIRM,
  //     CW_ENTITY_TYPE.COMPANY,
  //   ],
  // },

  // {
  //   type: CW_COMPONENT_TYPE.FILE_UPLOAD_COMPONENT,
  //   label: "Upload a scan copy of authorized signatory Signature",
  //   buttonCaption: "Upload",
  //   fileKey: "authorizedSignatoryDocument",
  //   showIn: [
  //     CW_ENTITY_TYPE.INDIVIDUAL,
  //     // CW_ENTITY_TYPE.PARTNERSHIP_FIRM,
  //     // CW_ENTITY_TYPE.COMPANY,
  //   ],
  // },
  {
    type: CW_COMPONENT_TYPE.FILE_UPLOAD_COMPONENT,
    label: "Upload Government PAN/Passport",
    buttonCaption: "Upload",
    fileKey: "govtApprovedIDProofDocument",
    showIn: [
      CW_ENTITY_TYPE.INDIVIDUAL,
      // CW_ENTITY_TYPE.PARTNERSHIP_FIRM,
      // CW_ENTITY_TYPE.COMPANY,
    ],
  },
  {
    type: CW_COMPONENT_TYPE.FILE_UPLOAD_COMPONENT,
    label: "Registration Certificate (PDF Only)",
    buttonCaption: "Upload",
    fileKey: "govtApprovedIDProofDocument",
    showIn: [
      // CW_ENTITY_TYPE.INDIVIDUAL,
      CW_ENTITY_TYPE.PARTNERSHIP_FIRM,
      CW_ENTITY_TYPE.COMPANY,
    ],
  },
];

export const CD_POC_FORM_STRUCTURE_LIST = [
  {
    type: CW_COMPONENT_TYPE.DROP_DOWN,
    name: "entityType",
    options: CW_TYPE_OF_ENTITY,
    disabledOptionCaption: "Customer Entity Type",
    showIn: [],
  },
  {
    type: CW_COMPONENT_TYPE.RADIO,
    name: "currencyOptedForBilling",
    legend: "Currency Opted for Billing",
    options: CW_CURRENCY_OPTED_FOR_BILLING,
    showIn: [],
  },
  {
    type: CW_COMPONENT_TYPE.DROP_DOWN,
    name: "selectedTatvicEntity",
    options: CW_ORGANISATION_TYPE,
    disabledOptionCaption: "Select Tatvic Entity",
    showIn: [],
  },
  {
    type: CW_COMPONENT_TYPE.RADIO,
    name: "nationality",
    legend: "Nationality",
    options: CW_NATIONALITY_OPTIONS,
    showIn: [],
  },
  {
    type: CW_COMPONENT_TYPE.DROP_DOWN,
    disabledOptionCaption: "Select the Type of services",
    name: "typesOfServices",
    options: CW_TYPES_OF_SERVICES,
    showIn: [],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "customerName",
    label: "Customer legal entity name",
    placeHolder: "Customer legal entity name",
    showIn: [],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "emailIDOfCustomerWhereContractToShare",
    label: "Email ID Of the Customer",
    placeHolder:
      "Email ID of the Customer on which the Contract Link will be sent",
    showIn: [],
  },
  {
    type: CW_COMPONENT_TYPE.DATE_PICKER,
    name: "effectiveDateOfService",
    label: "Effective Date Of Service",
    placeHolder:
      "Enter the date from which the customer wishes to avail the services",
    showIn: [],
  },
  {
    type: CW_COMPONENT_TYPE.DATE_PICKER,
    name: "currentDayDate",
    label: "Current Day's Date",
    placeHolder: "Example: January 7, 2019",
    showIn: [],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "oneTimeInvoice",
    label: "One Time Invoice",
    dataType: "number",
    placeHolder: "One Time Invoice",
    showIn: [CW_SERVICES.GA_4, CW_SERVICES.CRO],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "statementOfWorkLink",
    label: "Statement of Work Link",
    placeHolder: "Statement of Work Link",
    showIn: [CW_SERVICES.GA_4, CW_SERVICES.CRO],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "platFormFeeDBM",
    label:
      "Initial Platform setup fee - DBM (Please enter 0 if not applicable)",
    dataType: "number",
    placeHolder: "Initial Platform setup fee - DBM",
    showIn: [
      CW_SERVICES.DBM,
      CW_SERVICES.DBM_DCM_DRM,
      // CW_SERVICES.DCM,
      CW_SERVICES.DBM_DCM,
    ],
  },
  /* {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "minMonthlyFeeApplicableToCustomer",
    label: "Minimum Monthly Fee applicable",
    dataType: "number",
    placeHolder: "Minimum Monthly Fee applicable to the customer",
    showIn: [
     // CW_SERVICES.DBM,
      // CW_SERVICES.DBM_DCM_DRM,
     // CW_SERVICES.DCM,
      //CW_SERVICES.DBM_DCM,
    ],
  }, */
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "minMonthlyFeeApplicableToCustomer", // "monthlyDBMServiceFeesForCustomer",

    label:
      "Monthly Minimum Service Fees for Display and Video 360 Services (DBM)  (Please enter 0 if not applicable)",
    dataType: "number",
    placeHolder:
      "Monthly Minimum Service Fees for Display and Video 360 Services (DBM)",
    showIn: [
      CW_SERVICES.DBM,
      CW_SERVICES.DBM_DCM_DRM,
      // CW_SERVICES.DCM,
      CW_SERVICES.DBM_DCM,
    ],
  },
  /* {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "monthlyCampaignManagerExchangeRate",
    label: "Monthly Campaign Manager Exchange Rate",
    placeHolder: "Monthly Campaign Manager Exchange Rate in Percentage",
    dataType: "number",
    showIn: [
      CW_SERVICES.DBM,
      CW_SERVICES.DBM_DCM_DRM,
      CW_SERVICES.DCM,
      CW_SERVICES.DBM_DCM,
    ],
  }, */
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "minFeesPerMonthInPercentage",
    label:
      "Tatvic Percentage of commission for Display and Video 360 service (DBM) (Please enter 0 if not applicable)",
    dataType: "number",
    placeHolder:
      "Tatvic Percentage of commission for Display and Video 360 service (DBM)",
    showIn: [
      CW_SERVICES.DBM,
      CW_SERVICES.DBM_DCM_DRM,
      // CW_SERVICES.DCM,
      CW_SERVICES.DBM_DCM,
    ],
  },
  {
    type: CW_COMPONENT_TYPE.RADIO,
    name: "SupportHours",
    legend: "Need support hours?",
    options: CW_SUPPORT_HOURS_OPTIONS,
    showIn: [
      CW_SERVICES.DBM_DCM_DRM,

    ],
  },
  {

    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "noOfHoursForSupport",
    label:
      "Number of hours for support",
    dataType: "number",
    placeHolder: "Block of x hours",
    showIn: [
      CW_SERVICES.DBM_DCM_DRM,
    ],
  },
  {

    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "periodInMonth",
    label:
      "Period (Months)",
    dataType: "number",
    placeHolder: "",
    showIn: [
      CW_SERVICES.DBM_DCM_DRM,
    ],
  },
  {

    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "supportFees",
    label:
      "Support hours fees",
    dataType: "number",
    placeHolder: "",
    showIn: [
      CW_SERVICES.DBM_DCM_DRM,
    ],
  },
  // {
  //   type: CW_COMPONENT_TYPE.RADIO,
  //   name: "onTimeTraining",
  //   legend: "One time training?",
  //   options: CW_SUPPORT_HOURS_OPTIONS ,
  //   showIn: [
  //     CW_SERVICES.DBM_DCM_DRM,

  //   ],
  // },
  {

    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "trainingFees",
    label:
      "Training Fees (Please enter 0 if not applicable)",
    dataType: "number",
    placeHolder: "",
    showIn: [
      CW_SERVICES.DBM_DCM_DRM,
    ],
  },
  {

    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "initialSetupFeesCampaignManager",
    label:
      "Initial Setup Fees Campaign Manager - AD Service (Please enter 0 if not applicable)",
    dataType: "number",
    placeHolder: "Initial Setup fee - Campaign Manager Ad Service Network",
    showIn: [
      CW_SERVICES.DBM_DCM,
      CW_SERVICES.DBM_DCM_DRM,
      CW_SERVICES.DCM_DRM,
      CW_SERVICES.DCM,
    ],
  },
  /* {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "monthlyCPMRates0to1Cr",
    label: "Monthly CPM Rates 0 to 10 (Please enter 0 if not applicable)",
    dataType: "number",
    placeHolder: "Monthly CPM Rates- From 0 to 100,000,000",
    showIn: [CW_SERVICES.DBM_DCM],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "monthlyCPMRates1to25Cr",
    label: "Monthly CPM Rates 1 to 2.5 Cr",
    dataType: "number",
    placeHolder: "Monthly CPM Rates- From 100,000,001 to 250,000,000",
    showIn: [CW_SERVICES.DBM_DCM],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "monthlyCPMRates5to10Cr",
    label: "Monthly CPM Rates 5 to 10 Cr (Please enter 0 if not applicable)",
    dataType: "number",
    placeHolder: "Monthly CPM Rates- From 500,000,001 to 1,000,000,000",
    showIn: [CW_SERVICES.DBM_DCM],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "monthlyCPMRates10to20Cr",
    label: "Monthly CPM Rates 10 to 20 Cr (Please enter 0 if not applicable)",
    dataType: "number",
    placeHolder: "Monthly CPM Rates- From 1,000,000,001 to 2,000,000,000",
    showIn: [CW_SERVICES.DBM_DCM],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "monthlyCPMRatesMoreThan20Cr",
    label:
      "Monthly CPM Rates More Than 20 Cr (Please enter 0 if not applicable)",
    dataType: "number",
    placeHolder: "Monthly CPM Rates- More than 2,000,000,001",
    showIn: [CW_SERVICES.DBM_DCM],
  }, */
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "campaignManagerServiceFeesDCMCPM",
    label:
      "DCM CPM rate for Campaign Manager Ad Service (DCM) (Please enter 0 if not applicable)",
    dataType: "number",
    placeHolder: "DCM CPM rate for Campaign Manager Ad Service (DCM)",
    showIn: [
      CW_SERVICES.DBM_DCM,
      CW_SERVICES.DCM,
      CW_SERVICES.DBM_DCM_DRM,
      CW_SERVICES.DCM_DRM,
    ],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "campaignManagerServiceFeesVideoCPM",
    label:
      "Video CPM rate for Campaign Manager Ad Service (DCM) (Please enter 0 if not applicable)",
    dataType: "number",
    placeHolder: "Video CPM rate for Campaign Manager Ad Service (DCM)",
    showIn: [
      CW_SERVICES.DBM_DCM,
      CW_SERVICES.DCM,
      CW_SERVICES.DBM_DCM_DRM,
      CW_SERVICES.DCM_DRM,
    ],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "campaignManagerServiceFeesAdvDisplayUpCharge",
    label:
      "Advanced Display Upcharge rate for Campaign Manager Ad Service (DCM) (Please enter 0 if not applicable)",
    dataType: "number",
    placeHolder:
      "Advanced Display Upcharge rate for Campaign Manager Ad Service (DCM)",
    showIn: [
      CW_SERVICES.DBM_DCM,
      CW_SERVICES.DCM,
      CW_SERVICES.DBM_DCM_DRM,
      CW_SERVICES.DCM_DRM,
    ],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "campaignManagerServiceFeesAdvDisplayCPC",
    label:
      "Display CPC rate for Campaign Manager Ad Service (DCM) (Please enter 0 if not applicable)",
    dataType: "number",
    placeHolder: "Display CPC rate for Campaign Manager Ad Service (DCM)",
    showIn: [
      CW_SERVICES.DBM_DCM,
      CW_SERVICES.DCM,
      CW_SERVICES.DBM_DCM_DRM,
      CW_SERVICES.DCM_DRM,
    ],
  },
  /* {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "adLimitKbs",
    label: "Ad Size Limit (Please enter 0 if not applicable)",
    dataType: "number",
    placeHolder: "Ad Size limit Kbytes",
    showIn: [CW_SERVICES.DBM_DCM],
  }, */
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "adServingRateFormatFollowingRate",
    label:
      "Customer will pay Service Provider fees in addition to the Standard Ad Serving Rates for Enhanced Formats at the following rate: CPM",
    placeHolder: "Customer will pay Service Provider fees",
    showIn: [CW_SERVICES.DBM_DCM],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "monthlyServiceFeeForClicks",
    label: "Monthly Service Fee for Clicks - Click Rate",
    placeHolder: "Monthly Service Fee for Clicks - Click Rate",
    showIn: [CW_SERVICES.DBM_DCM],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "minServiceFeesDCM",
    label: "MINIMUM SERVICE FEE: DCM (Please enter 0 if not applicable)",
    dataType: "number",
    placeHolder: "MINIMUM SERVICE FEE: DCM",
    showIn: [CW_SERVICES.DBM_DCM],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "rfpModuleSetupFees",
    label: "RFP MODULE: Set-Up Fee (Please enter 0 if not applicable)",
    dataType: "number",
    placeHolder: "RFP MODULE: Set-Up Fee",
    showIn: [CW_SERVICES.DBM_DCM],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "rfpModuleRate",
    label: "RFP MODULE: Rate (Please enter 0 if not applicable)",
    dataType: "number",
    placeHolder: "RFP MODULE: Rate",
    showIn: [CW_SERVICES.DBM_DCM],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "basicFloodLight",
    label:
      "Basic Floodlight (f/k/a Spotlight) CPM Price (Please enter 0 if not applicable)",
    dataType: "number",
    placeHolder: "Basic Floodlight (f/k/a Spotlight) CPM Price",
    showIn: [CW_SERVICES.DBM_DCM],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "dynamicFloodLight",
    label:
      "Dynamic Floodlight (f/k/a Floodlight) Set-Up Fee DCM (Please enter 0 if not applicable)",
    dataType: "number",
    placeHolder: "Dynamic Floodlight (f/k/a Floodlight) Set-Up Fee DCM",
    showIn: [CW_SERVICES.DBM_DCM],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "postBillingCreditLimit",
    label:
      "Post Billing Credit Limit for DCM to Customer/Month (Please enter 0 if not applicable)",
    dataType: "number",
    placeHolder: "Post Billing Credit Limit for DCM to Customer/Month",
    showIn: [
      CW_SERVICES.DBM_DCM,
      CW_SERVICES.DCM,
      CW_SERVICES.DCM_DRM,
      CW_SERVICES.DBM_DCM_DRM,
    ],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "totalOfBilledPlusUnBilled",
    label:
      "Total of billed plus unbilled amount reaches or exceeds the limit of 'X' Amount/Month: DCM",
    showIn: [CW_SERVICES.DBM_DCM],
    placeHolder: "",
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "doCustomerShallHaveMakePayment",
    label:
      "Customer shall have to make payment of previous unpaid invoices or make advance payment to meet the requirement of INR __ credit limit in respect of this post billing account.",
    placeHolder: "",
    showIn: [CW_SERVICES.DBM_DCM],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "sa360InitialSetupFee",
    dataType: "number",
    label: "SA360 Initial Setup Fee (Please enter 0 if not applicable)",
    placeHolder: "SA360 Initial Setup Fee",
    showIn: [CW_SERVICES.DCM_DRM, CW_SERVICES.DBM_DCM_DRM],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "sa360MonthlyPercentage",
    dataType: "number",
    label:
      "SA360 Monthly Service Fee Percentage (Please enter 0 if not applicable)",
    placeHolder: "SA360 Monthly Service Fee Percentage",
    showIn: [CW_SERVICES.DCM_DRM, CW_SERVICES.DBM_DCM_DRM],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "gSuiteNumberOfUsersByClient",
    label:
      "How Many number of users are required by the client (Please enter 0 if not applicable)",
    dataType: "number",
    placeHolder: "How Many number of users are required by the client",
    showIn: [CW_SERVICES.G_SUITE],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "gSuitePricePerMonth",
    label: "Pricing per month (Please enter 0 if not applicable)",
    dataType: "number",
    placeHolder: "Pricing per month",
    showIn: [CW_SERVICES.G_SUITE],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "gSuiteAdditionalUserCharge",
    label: "Additional User Charge (Please enter 0 if not applicable)",
    dataType: "number",
    placeHolder: "Additional User charge",
    showIn: [CW_SERVICES.G_SUITE],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "gSuiteAdditionalStorageCharge",
    label: "Additional Storage Charge (Please enter 0 if not applicable)",
    dataType: "number",
    placeHolder: "Additional Storage charge",
    showIn: [CW_SERVICES.G_SUITE],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "gSuiteYearlyCharge",
    label: "Yearly charge for G-suite (Please enter 0 if not applicable)",
    dataType: "number",
    placeHolder: "Yearly charge for G-suite",
    showIn: [CW_SERVICES.G_SUITE],
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "indicativeBudgetLimit",
    label:
      "Indicative Budget limit for GCP Services (Please enter 0 if not applicable)",
    placeHolder: "Indicative Budget limit for GCP Services",
    dataType: "number",
    showIn: [CW_SERVICES.GCP],
  },
];

export const CUSTOMER_FORM_LIST = [
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "firstName",
    id: "cust_first_name",
    label: "First Name",
    placeHolder: "Enter First Name",
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "lastName",
    label: "Last Name",
    id: "cust_last_name",
    placeHolder: "Enter Last Name",
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "email",
    id: "cust_email",
    label: "Email Address",
    placeHolder: "Enter Email Address",
  },
  {
    type: CW_COMPONENT_TYPE.DROP_DOWN,
    name: "role",
    options: CW_USER_ROLE_TYPE,
    id: "cust_role",
    disabledOptionCaption: "User Role",
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "password",
    label: "Password",
    id: "cust_password",
    dataType: "password",
    placeHolder: "Enter Password",
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "confirmPassword",
    label: "Confirm Password",
    id: "cust_confirm_password",
    dataType: "password",
    placeHolder: "Enter Confirm Password",
  },
  {
    type: CW_COMPONENT_TYPE.TEXT_BOX,
    name: "mobileNumber",
    label: "Mobile Number",
    id: "cust_mobile_number",
    placeHolder: "Enter Mobile Number",
  },
];
