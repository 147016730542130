import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import DocuSign from "../redux/services/docusign";
import OAuthImplicit from "../redux/services/oauth-implimentation";
import { useDispatch } from "react-redux";
import { postSignedDocument } from "../redux/actions/click-wrap-actions";

function ThankYouPage() {
  const [currentMessageObject, setCurrentMessageObject] = useState({});

  // const docuSign = new DocuSign();
  // const authImplicit = new OAuthImplicit();
  // const dispatch = useDispatch();

  useEffect(async () => {
    // const checkVariable = await localStorage.getItem("isFlowCompleted");
    // if (window.location.search.toString().includes("signing_complete")) {
      // await docuSign.getEnvelope().then((response) => {
      setCurrentMessageObject({
        heading: "Congratulations!!",
        description:
          "Your form have been submitted, Someone will get back to you",
      });

      // dispatch(
      //   postSignedDocument(localStorage.getItem("clickWrapID"), {
      //     envelope_id: localStorage.getItem("envelopeId"),
      //     token: localStorage.getItem("accessToken"),
      //     account_id: localStorage.getItem("accountId"),
      //     final_signed: localStorage.getItem("isAdminApproval")
      //       ? true
      //       : undefined,
      //   })
      // ).then((finalDocumentResult) => {
      //   localStorage.setItem("isFlowCompleted", "DONE");
      // });
      // // });
    // } 
    // else {
    //   setCurrentMessageObject({
    //     heading: "Processing",
    //     description: "We are processing, Please wait for few minutes.",
    //   });
    //   await authImplicit.receiveHash(window.location.hash).then(() => {
    //     docuSign.sendEnvelope().then((response) => {
    //       localStorage.setItem("envelopeId", response?.envelopeId);
    //       docuSign
    //         .openRecipientPreview(response?.envelopeId)
    //         .then((recipientPreview) => {
    //           localStorage.setItem("isFlowCompleted", "NOT_DONE");
    //           window.open(recipientPreview?.url);
    //         });
    //     });
    //   });
    // }

    if (checkVariable === "NOT_DONE") {
      // docuSign.getEnvelope().then(async (response)=>{
      //   // localStorage.setItem("signed_pdf",response.resultsEnvelopeJson);
      //   postSignedDocument(localStorage.getItem("clickWrapID"), {
      //     envelope_id: localStorage.getItem("envelopeId"),
      //     signed_pdf:response.resultsEnvelopeJson
      //   });
      //   // console.log(response.resultsEnvelopeJson);
      // });
    } else {
      console.log("Window Location Hash", window.location.hash);
    }
  }, []);

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Grid
        container
        direction={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Grid item>
          <Typography variant={"h3"}>
            {currentMessageObject?.heading}
          </Typography>
        </Grid>
        <Grid item>
          <Typography>{currentMessageObject?.description}</Typography>
        </Grid>
      </Grid>
    </div>
  );
}

ThankYouPage.propTypes = {
  messageObject: PropTypes.object,
};

export default ThankYouPage;
