import {
  REACT_APP_DS_API_CORS_PROXIES,
  CW_TYPES_OF_SERVICES_DATA_MAPPING,
} from "../../constants/click-wrap-constants";

const docName = "TatvicAnalyticsAgreement.pdf";
const sdkString = "codeEg_react";
const urlFrag = "restapi/v2.1";



class DocuSign {
  constructor(app) {
    this.app = app;
    this.sendEnvelope = this.sendEnvelope.bind(this);
  }


  async sendEnvelope() {
    const envelopeRequest = {
      emailSubject:
        "Please sign the attached document - " +
        localStorage.getItem("name") +
        " - " +
        CW_TYPES_OF_SERVICES_DATA_MAPPING[
        localStorage.getItem("typesOfServices")
        ],
      status: "sent",
      recipients: {
        signers: [
          {
            email: localStorage.getItem("email"),
            name: localStorage.getItem("name"),
            recipientId: "1",
            routingOrder: "1",

            "tabs": {
              "initialHereTabs": [
                {
                  "tabLabel": "clientInitial",
                  "anchorString": "clientInitial",
                  "anchorXOffset": "0",
                  "anchorYOffset": "-5",
                  "anchorIgnoreIfNotPresent": "true"
                }
              ],
              signHereTabs: [
                {
                  tabLabel: "clientSign",
                  anchorString: "clientSign",
                  anchorXOffset: "40",
                  anchorYOffset: "3",
                  anchorIgnoreIfNotPresent: "true"
                }
              ]
            }


          },
          {
            email: "rajiv@tatvic.com",
            name: "Rajiv Pathak",
            recipientId: "2",
            routingOrder: "2",
            "tabs": {
              "initialHereTabs": [
                {
                  "tabLabel": "tatvicInitial",
                  "anchorString": "tatvicInitial",
                  "anchorXOffset": "0",
                  "anchorYOffset": "-5",
                  "anchorIgnoreIfNotPresent": "true"
                }
              ],
              signHereTabs: [
                {
                  tabLabel: "tatvicSign",
                  anchorString: "tatvicSign",
                  anchorXOffset: "50",
                  anchorYOffset: "3",
                  anchorIgnoreIfNotPresent: "true"
                }
              ]
            }

          },
        ],
      },

      documents: [
        {
          name:
            localStorage.getItem("selectedTatvicEntity") +
            " - " +
            CW_TYPES_OF_SERVICES_DATA_MAPPING[
            localStorage.getItem("typesOfServices")
            ],
          fileExtension: "pdf",
          documentId: "1",
          documentBase64: localStorage.getItem("filePDF"),
        },
      ],
    };

    try {
      const url = `${REACT_APP_DS_API_CORS_PROXIES[localStorage.getItem("baseUri")]
        }/${urlFrag}/accounts/${localStorage.getItem("accountId")}/envelopes`;
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(envelopeRequest),
        headers: new Headers({
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          Accept: `application/json`,
          "Content-Type": "application/json",
          "X-DocuSign-SDK": sdkString,
        }),
      });
      const data = response && response.ok && (await response.json());
      let result;
      const headers = response.headers;
      const availableApiReqHeader = headers.get("X-RateLimit-Remaining");
      const availableApiRequests = availableApiReqHeader
        ? parseInt(availableApiReqHeader, 10)
        : undefined;
      const apiResetHeader = headers.get("X-RateLimit-Reset");
      const apiRequestsReset = apiResetHeader
        ? new Date(parseInt(apiResetHeader, 10) * 1000)
        : undefined;
      const traceId = headers.get("X-DocuSign-TraceToken") || undefined;
      if (response.ok) {
        result = {
          success: true,
          errorMsg: undefined,
          envelopeId: data.envelopeId,
          availableApiRequests,
          apiRequestsReset,
          traceId,
        };
        // setTimeout(await this.openRecipientPreview(data.envelopeId),5000);
      } else {
        result = {
          success: false,
          errorMsg: response && (await response.text()),
          envelopeId: undefined,
          availableApiRequests,
          apiRequestsReset,
          traceId,
        };
      }
      return result;
    } catch (e) {
      const errorMsg =
        e.message === "Failed to fetch"
          ? "Networking error—check your Internet and DNS connections"
          : e.message;
      return {
        success: false,
        errorMsg,
        envelopeId: undefined,
        availableApiRequests: undefined,
        apiRequestsReset: undefined,
        traceId: undefined,
      };
    }
  }

  async getEnvelope() {
    try {
      const url =
        REACT_APP_DS_API_CORS_PROXIES[localStorage.getItem("baseUri")] +
        `/` +
        `${urlFrag}` +
        `/accounts/${localStorage.getItem("accountId")}` +
        `/envelopes/${localStorage.getItem("envelopeId")}/documents/combined`;
      const response = await fetch(url, {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          // Accept: `application/json`,
          "Content-Type": "application/json",
          "X-DocuSign-SDK": sdkString,
          Accept: "application/pdf",
          // "Content-Type": "application/json",
          "Content-Transfer-Encoding": "base64",
        }),
      });
      const data = response && response.ok && (await response.text());
      let result;
      const headers = response.headers;
      const availableApiReqHeader = headers.get("X-RateLimit-Remaining");
      const availableApiRequests = availableApiReqHeader
        ? parseInt(availableApiReqHeader, 10)
        : undefined;
      const apiResetHeader = headers.get("X-RateLimit-Reset");
      const apiRequestsReset = apiResetHeader
        ? new Date(parseInt(apiResetHeader, 10) * 1000)
        : undefined;
      const traceId = headers.get("X-DocuSign-TraceToken") || undefined;
      if (response.ok) {
        result = {
          success: true,
          errorMsg: undefined,
          resultsEnvelopeJson: data,
          availableApiRequests,
          apiRequestsReset,
          traceId,
        };
      } else {
        result = {
          success: false,
          errorMsg: response && (await response.text()),
          resultsEnvelopeJson: undefined,
          availableApiRequests,
          apiRequestsReset,
          traceId,
        };
      }
      return result;
    } catch (e) {
      const errorMsg =
        e.message === "Failed to fetch"
          ? "Networking error—check your Internet and DNS connections"
          : e.message;
      return {
        success: false,
        errorMsg,
        resultsEnvelopeJson: undefined,
        availableApiRequests: undefined,
        apiRequestsReset: undefined,
        traceId: undefined,
      };
    }
  }

  async openRecipientPreview(envelopeId) {
    try {
      const url = `${REACT_APP_DS_API_CORS_PROXIES[localStorage.getItem("baseUri")]
        }/${urlFrag}/accounts/${localStorage
          .getItem("accountId")
          .toString()
          .trim()}/envelopes/${envelopeId}/views/identity_manual_review`;

      const previewRequest = {
        returnUrl: process.env.REACT_APP_DS_APP_URL,
        authenticationMethod: "none",
        email: localStorage.getItem("email"),
        userName: localStorage.getItem("name"),
      };
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(previewRequest),
        headers: new Headers({
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          Accept: `application/json`,
          "Content-Type": "application/json",
          "X-DocuSign-SDK": sdkString,
        }),
      });
      return response && response.ok && (await response.json());
    } catch (e) {
      console.error(e);
    }
  }
}

export default DocuSign;
