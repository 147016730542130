import "regenerator-runtime/runtime";
import { TextField } from "@mui/material";
import ClickWrapDropDown from "../components/click-wrap-drop-down";
import ClickWrapRadioComponent from "../components/click-wrap-radio-component";
import ClickWrapCheckBoxGroup from "../components/click-wrap-checkbox-group";
import ClickWrapFileUpload from "../components/click-wrap-file-upload";
import React from "react";
import moment from "moment";
import { notify } from "react-notify-toast";
import { useLocation } from "react-router-dom";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers';
import { notificationType } from "../constants/color-constant";
import { display } from "@mui/system";
// import {useState} from 'react'
// import tr from "date-fns/locale/tr";
export const addValuesInDataObject = async (currentObject, type, e) => {


 let dataObject = currentObject;


 switch (type) {
   case "TEXT_BOX":
     if (e.target.value) {
       // showNotification("Please enter valid number", notificationType.ERROR);
       dataObject = Object.assign({}, dataObject, {
         [e.target.name]: e.target.value.replace(/,/g, ''),
       });
     } else if (isNaN(e.target.value)) {
       dataObject = Object.assign({}, dataObject, {
         [e.target.name]: ''
       })
     } else {
       dataObject = Object.assign({}, dataObject, {
         [e.target.name]: e.target.value,
         // [e.target.name]: new Intl.NumberFormat().format(e.target.value)
       });
     }


     break;
   case "CHECK_BOX_GROUP":
     // eslint-disable-next-line no-case-declarations
     let currentArray = dataObject[e.target.id] ? dataObject[e.target.id] : [];


     if (e.target.checked) {
       currentArray = currentArray.concat([e.target.name]);
     } else {
       currentArray = currentArray.filter(
         (element) => element !== e.target.name
       );
     }


     dataObject = Object.assign({}, dataObject, {
       [e.target.id]: currentArray,
     });
     break;


   case "FILE_UPLOAD_COMPONENT":
     // eslint-disable-next-line no-case-declarations
     const dataFile = await getBase64(e.target.files[0]).then((data) => {
       return data;
     });


     dataObject = Object.assign({}, dataObject, {
       [e.target.name]: dataFile,
     });


     break;


   case "DATE_PICKER":
     dataObject = Object.assign({}, dataObject, {
       [e.name]: e.value,
     });


     break;


   default:
     if (e.target.name === "isYourCorporateOfficeSameAsRegistered") {
       if (e.target.value === "YES") {
         dataObject = {
           ...dataObject,
           corporateOfficeAddress: dataObject.registeredOfficeAddress,
         };
       } else {
         dataObject.corporateOfficeAddress = "";
       }
     }
     if (e.target.name === "isRegisteredGSTEntity") {
       if (e.target.value === "YES") {
         dataObject.disabled = false


       } else {
         dataObject.disabled = true
       }
     }
     dataObject = Object.assign({}, dataObject, {
       [e.target.name]: e.target.value,
     });
     break;
 }


 return dataObject;
};


function numberWithCommas(x) {
 x = x.toString();
 var pattern = /(-?\d+)(\d{3})/;
 while (pattern.test(x))
   x = x.replace(pattern, "$1,$2");
 console.log("number", x);
 return x;
}
export function getBase64(file) {
 return new Promise((resolve, reject) => {
   const reader = new FileReader();
   reader.readAsDataURL(file);
   reader.onload = () => resolve(reader.result);
   reader.onerror = (error) => reject(error);
 });
}


export function getCommonComponent(
 type,
 elementData,
 dataObject,
 onChangeFunction,
 nationality
) {
 // console.log(nationality);
 console.log("cd-poc-elementData", elementData);
 // console.log(elementData.dataType);


 //  var [radioStatus,setRadioStatus] = useState("true")


 // function onChangeFunction(type,event){
 //   if(event.target.value == "NO"){
 //     // setRadioStatus("true")
 //     alert()


 //   }
 // }




 switch (type) {
   case "TEXT_BOX":
     return (
       <TextField
         disabled={(elementData.name == 'gstRegisteredNumber' && dataObject.disabled) ? true : false || (elementData.name == 'clientEngagedIn' && dataObject.disabled) ? true : false}
         fullWidth
         style={{ display: (elementData.name == 'gstRegisteredNumber' || elementData.name == ('clientEngagedIn')) && nationality != "NATIONAL" ? 'none' : 'block' }}
         onWheel={(event) => event.target.blur()}
         type="text"
         elementType={elementData?.dataType ? elementData.dataType : "text"}
         key={elementData.name}
         name={elementData.name}
         value={elementData.dataType == 'number' ? new Intl.NumberFormat('en-IN').format(dataObject[elementData.name]).replace(/[^0-9]/g, '')
           .replace(/(\d)(?=(\d\d)+\d$)/g, "$1,") : dataObject[elementData.name]
         }
         onInput={(e) => { if (elementData.dataType == 'number') { e.target.value = e.target.value.replace(/[^0-9]/g, ''); } }}
         onChange={(event) => (onChangeFunction(type, event))}
         label={elementData?.label}
         placeholder={elementData?.placeHolder}
         variant={"outlined"}
         multiline={elementData?.isWithMultiline}
         rows={elementData?.isWithMultiline && elementData.numberOfRows}
         size={"small"}
         InputLabelProps={(elementData?.label == "Corporate Office Address") ? {
           shrink: true,
         } : null}
       />


     );


   case "DROP_DOWN":
     return (
       <ClickWrapDropDown
         disabledOptionCaption={elementData.disabledOptionCaption}
         name={elementData.name}
         onChange={(event) => onChangeFunction(type, event)}
         allOptions={elementData.options}
         selectedValue={dataObject[elementData.name]}
       />
     );


   case "RADIO":
     {
       return (
         // console.log(elementData.legend),
         <ClickWrapRadioComponent
           style={{ display: "none" }}
           legend={elementData.legend}
           name={elementData.name}
           value={dataObject[elementData.name]}
           onChange={(event) => onChangeFunction(type, event)}
           options={elementData.options}
           nationality={nationality}
         />
       );
     }


   case "CHECK_BOX_GROUP":
     return (
       <ClickWrapCheckBoxGroup
         options={elementData.optionList}
         label={elementData.label}
         name={elementData.name}
         onChange={(event) => onChangeFunction(type, event)}
       />
     );


   case "FILE_UPLOAD_COMPONENT":
     return (
       <ClickWrapFileUpload
         fileKey={elementData.fileKey}
         label={elementData.label}
         buttonCaption={elementData.buttonCaption}
         onChange={(event) => onChangeFunction(type, event)}
       />
     );


   case "DATE_PICKER":
     return (
       <LocalizationProvider dateAdapter={AdapterDateFns}>
         <DatePicker
           value={
             dataObject[elementData.name] ? dataObject[elementData.name] : null
           }
           inputFormat="dd/MM/yyyy"
           label={elementData?.label}
           name={elementData.name}
           onChange={(event) =>
             onChangeFunction(type, { value: event, name: elementData?.name })
           }
           renderInput={(params) => (
             <TextField fullWidth size={"small"} {...params} />
           )}
         />
       </LocalizationProvider>
     );
 }
}


export function dateFormatter(stringValue) {
 return moment(stringValue).format("Do MMM YYYY hh:mm A");
}


export function showNotification(message, type) {
 notify.show(message, type, 5000);
}


export function useQuery() {
 const { search } = useLocation();
 return React.useMemo(() => new URLSearchParams(search), [search]);
}


export const base64toBlobURL = (data) => {
 const linkSource = `data:application/pdf;base64,${data}`;
 const downloadLink = document.createElement("a");
 const fileName = Math.floor(Math.random() * 1000000) + ".pdf";
 downloadLink.href = linkSource;
 downloadLink.download = fileName;
 downloadLink.click();
};


export const openSOWURL = (data)=>{
  const linkSource = data;
  const downloadLink = document.createElement("a");
  downloadLink.target='_blank';
  downloadLink.href = linkSource;
  downloadLink.click();
}
