
import * as React from "react";

import { Button, TextField } from "@mui/material";
import axios from "axios";
import { async } from "regenerator-runtime";
import AxiosInstance from "../../redux/AxiosInstance";
import { useEffect } from "react";

function gmpAgreement(props) {
    var [templateId, setTemplateId] = React.useState("")
    var { entity } = props.data
    var [gmpAgreement, setGMPAgreement] = React.useState(
        {
            entityType: entity,
            introductionSection: '',
            recitalSection: '',
            clouse1ScopeoofService: '',
            clouse2InvoicePayment: '',
            clouse3confidentiality: '',
            clouse4representations: '',
            clouse5limitations: '',
            clouse6ipr: '',
            clouse7termination: '',
            clouse8settlement: '',
            clouse9miscellaneous: '',
            annexureSection: '',
            footer: '',
            header: ''
        }
    )
    async function create(e) {
        e.preventDefault()

        await AxiosInstance.post('/api/v1/gmpAgreement', {
            entityType: entity,
            introductionSection: gmpAgreement.introductionSection,
            recitalSection: gmpAgreement.recitalSection,
            clouseSection: {
                clouse1ScopeoofService: gmpAgreement.clouse1ScopeoofService,
                clouse2InvoicePayment: gmpAgreement.clouse2InvoicePayment,
                clouse3confidentiality: gmpAgreement.clouse3confidentiality,
                clouse4representations: gmpAgreement.clouse4representations,
                clouse5limitations: gmpAgreement.clouse5limitations,
                clouse6ipr: gmpAgreement.clouse6ipr,
                clouse7termination: gmpAgreement.clouse7termination,
                clouse8settlement: gmpAgreement.clouse8settlement,
                clouse9miscellaneous: gmpAgreement.clouse9miscellaneous
            },
            annexureSection: gmpAgreement.annexureSection,
            footer: gmpAgreement.footer,
            header: gmpAgreement.header
        }).then(res => console.log("data is send successfully")).catch(e => console.log(e.message))
    }
    async function update(e) {
        e.preventDefault()

        await AxiosInstance.put('/api/v1/gmpAgreement/?id=' + templateId, {
            entityType: entity,
            introductionSection: gmpAgreement.introductionSection,
            recitalSection: gmpAgreement.recitalSection,
            clouseSection: {
                clouse1ScopeoofService: gmpAgreement.clouse1ScopeoofService,
                clouse2InvoicePayment: gmpAgreement.clouse2InvoicePayment,
                clouse3confidentiality: gmpAgreement.clouse3confidentiality,
                clouse4representations: gmpAgreement.clouse4representations,
                clouse5limitations: gmpAgreement.clouse5limitations,
                clouse6ipr: gmpAgreement.clouse6ipr,
                clouse7termination: gmpAgreement.clouse7termination,
                clouse8settlement: gmpAgreement.clouse8settlement,
                clouse9miscellaneous: gmpAgreement.clouse9miscellaneous
            },
            annexureSection: gmpAgreement.annexureSection,
            footer: gmpAgreement.footer,
            header: gmpAgreement.header
        }).then(res => console.log("data is send successfully")).catch(e => console.log(e.message))
    }
    useEffect(() => {
        {
            entity != '' ? AxiosInstance.get(`/api/v1/gmpAgreement/${entity}`).then((res) => {
                console.log("res", res);
                var { data } = res.data
                setTemplateId(res.data.data._id)
                setGMPAgreement({
                    entityType: entity,
                    introductionSection: data.introductionSection,
                    recitalSection: data.recitalSection,
                    clouse1ScopeoofService: data.clouseSection.clouse1ScopeoofService,
                    clouse2InvoicePayment: data.clouseSection.clouse2InvoicePayment,
                    clouse3confidentiality: data.clouseSection.clouse3confidentiality,
                    clouse4representations: data.clouseSection.clouse4representations,
                    clouse5limitations: data.clouseSection.clouse5limitations,
                    clouse6ipr: data.clouseSection.clouse6ipr,
                    clouse7termination: data.clouseSection.clouse7termination,
                    clouse8settlement: data.clouseSection.clouse8settlement,
                    clouse9miscellaneous: data.clouseSection.clouse9miscellaneous,
                    annexureSection: data.annexureSection,
                    footer: data.footer,
                    header: data.header
                })

            }).catch(err => console.log(err)) : null
        }

    }, [entity])
    return (<>
        <form>
            <TextField
                sx={{
                    marginTop: '30px',
                    width: '100%'
                }}
                id="outlined-textarea"
                label={"Introduction"}
                multiline
                maxRows={16}
                // focused="true"
                value={gmpAgreement.introductionSection}
                onChange={(e) => setGMPAgreement({ ...gmpAgreement, introductionSection: e.target.value })}
            />
            <TextField
                sx={{
                    marginTop: '30px',
                    width: '100%'
                }}
                id="outlined-textarea"
                label={"Recital"}
                multiline
                maxRows={16}
                // focused="true"
                value={gmpAgreement.recitalSection}
                onChange={(e) => setGMPAgreement({ ...gmpAgreement, recitalSection: e.target.value })}
            />
            <TextField
                sx={{
                    marginTop: '30px',
                    width: '100%'
                }}
                id="outlined-textarea"
                label={"Clouse 1: Scope of Service"}
                multiline
                maxRows={16}
                // focused="true"
                value={gmpAgreement.clouse1ScopeoofService}
                onChange={(e) => setGMPAgreement({ ...gmpAgreement, clouse1ScopeoofService: e.target.value })}
            />
            <TextField
                sx={{
                    marginTop: '30px',
                    width: '100%'
                }}
                id="outlined-textarea"
                label={"Clouse 2: Invoice Payment"}
                multiline
                maxRows={16}
                // focused="true"
                value={gmpAgreement.clouse2InvoicePayment}
                onChange={(e) => setGMPAgreement({ ...gmpAgreement, clouse2InvoicePayment: e.target.value })}
            />
            <TextField
                sx={{
                    marginTop: '30px',
                    width: '100%'
                }}
                id="outlined-textarea"
                label={"Clouse 3: CONFIDENTIALITY "}
                multiline
                maxRows={16}
                // focused="true"
                value={gmpAgreement.clouse3confidentiality}
                onChange={(e) => setGMPAgreement({ ...gmpAgreement, clouse3confidentiality: e.target.value })}
            />
            <TextField
                sx={{
                    marginTop: '30px',
                    width: '100%'
                }}
                id="outlined-textarea"
                label={"Clouse 4: REPRESENTATIONS "}
                multiline
                maxRows={16}
                // focused="true"
                value={gmpAgreement.clouse4representations}
                onChange={(e) => setGMPAgreement({ ...gmpAgreement, clouse4representations: e.target.value })}
            />
            <TextField
                sx={{
                    marginTop: '30px',
                    width: '100%'
                }}
                id="outlined-textarea"
                label={"Clouse 5: LIMITATIONS "}
                multiline
                maxRows={16}
                // focused="true"
                value={gmpAgreement.clouse5limitations}
                onChange={(e) => setGMPAgreement({ ...gmpAgreement, clouse5limitations: e.target.value })}
            />
            <TextField
                sx={{
                    marginTop: '30px',
                    width: '100%'
                }}
                id="outlined-textarea"
                label={"Clouse 6: IPR "}
                multiline
                maxRows={16}
                // focused="true"
                value={gmpAgreement.clouse6ipr}
                onChange={(e) => setGMPAgreement({ ...gmpAgreement, clouse6ipr: e.target.value })}
            />
            <TextField
                sx={{
                    marginTop: '30px',
                    width: '100%'
                }}
                id="outlined-textarea"
                label={"Clouse 7: Termination"}
                multiline
                maxRows={16}
                // focused="true"
                value={gmpAgreement.clouse7termination}
                onChange={(e) => setGMPAgreement({ ...gmpAgreement, clouse7termination: e.target.value })}
            />
            <TextField
                sx={{
                    marginTop: '30px',
                    width: '100%'
                }}
                id="outlined-textarea"
                label={"Clouse 8: SETTLEMENT "}
                multiline
                maxRows={16}
                // focused="true"
                value={gmpAgreement.clouse8settlement}
                onChange={(e) => setGMPAgreement({ ...gmpAgreement, clouse8settlement: e.target.value })}
            />
            <TextField
                sx={{
                    marginTop: '30px',
                    width: '100%'
                }}
                id="outlined-textarea"
                label={"Clouse 9: MISCELLANEOUS "}
                multiline
                maxRows={16}
                // focused="true"
                value={gmpAgreement.clouse9miscellaneous}
                onChange={(e) => setGMPAgreement({ ...gmpAgreement, clouse9miscellaneous: e.target.value })}
            />

            <TextField
                sx={{
                    marginTop: '30px',
                    width: '100%'
                }}
                id="outlined-textarea"
                label={"Annexure"}
                multiline
                maxRows={16}
                // focused="true"
                value={gmpAgreement.annexureSection}
                onChange={(e) => setGMPAgreement({ ...gmpAgreement, annexureSection: e.target.value })}
            />
            <TextField
                sx={{
                    marginTop: '30px',
                    width: '100%'
                }}
                id="outlined-textarea"
                label={"Footer"}
                multiline
                maxRows={16}
                // focused="true"
                value={gmpAgreement.footer}
                onChange={(e) => setGMPAgreement({ ...gmpAgreement, footer: e.target.value })}
            />
            <TextField
                sx={{
                    marginTop: '30px',
                    width: '100%'
                }}
                id="outlined-textarea"
                label={"Header"}
                multiline
                maxRows={16}
                // focused="true"
                value={gmpAgreement.header}
                onChange={(e) => setGMPAgreement({ ...gmpAgreement, header: e.target.value })}
            />

            <Button type="submit" sx={{ margin: "10px 0px", width: '100%' }} variant="contained" onClick={(e) => create(e)}>Create GMP Agreement</Button>
            <Button type="submit" sx={{ margin: "10px 0px", width: '100%' }} variant="contained" onClick={(e) => update(e)}>Update GMP Agreement</Button>
        </form>
    </>)
}

export default gmpAgreement;