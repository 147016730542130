import * as ActionConstants from "../constants/redux-constants";

const currentUserReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionConstants.CURRENT_USER:
      return payload;
  }
  return state;
};

export default currentUserReducer;
