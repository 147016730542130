import React from "react";
import { Button, FormControl, FormLabel } from "@mui/material";
import PropTypes from "prop-types";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

function ClickWrapFileUpload({ label, buttonCaption, fileKey, onChange }) {
  console.log(fileKey);
  return (
    <div>
      <FormLabel component={"legend"}>{label}</FormLabel>
      <div style={{ width: "100%" }}>
        <input type="file" name={fileKey} onChange={onChange} />
      </div>
    </div>
  );
}

ClickWrapFileUpload.propTypes = {
  label: PropTypes.string.isRequired,
  buttonCaption: PropTypes.string.isRequired,
  fileKey: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ClickWrapFileUpload;
