import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import ClickWrapEmptyTable from "../../components/empty-components/click-wrap-empty-table";
import ClickWrapHeadRow from "../../components/click-wrap-head-row";
import { useDispatch, useSelector } from "react-redux";
import { addUser, getUserList } from "../../redux/actions/user-actions";
import {
  addValuesInDataObject,
  dateFormatter,
  showNotification,
} from "../../utilities/common-utility-functions";
import { Button, Chip } from "@mui/material";
import ClickWrapDialog from "../../components/click-wrap-dialog";
import UserForm from "../../forms/user-form";
import {
  chipTypeBasedOnRole,
  notificationType,
} from "../../constants/color-constant";
import {
  clickWrapUserRole,
  tablePaginationStaticList,
} from "../../constants/click-wrap-constants";
import { outerListWrapper } from "../../constants/common-style-object";
import axios from "axios";
import AxiosInstance from "../../redux/AxiosInstance";
import { func } from "prop-types";

function UsersListingScreen() {
  const [deleteId, setDeleteID] = useState()
  const [pageSize, setPageSize] = React.useState(10);
  const [isOpenUserModal, setIsOpenUserModal] = React.useState(false);
  const [dataObject, setDataObject] = useState({});

  const dispatch = useDispatch();

  async function deleteUser(id) {
    await AxiosInstance.delete(`/api/v1/users/${id}`).then(function(res){
      showNotification(
        res.data.message,
         notificationType.
         SUCCESS
       )
       dispatch(getUserList())

    }).catch(err=>{
      showNotification(err.response.data.message,notificationType.ERROR)
    })
  }
  const userList = useSelector((state) => state.users);
  console.log('userList', userList);
  useEffect(() => {
    dispatch(getUserList());
  }, []);
  

  const columns = [
    // {
    //   field: "id",
    //   headerName: "ID",
    //   width: 250,
    // },
    {
      field: "firstName",
      headerName: "First Name",
      editable: false,
      flex: 0.7,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: "lastName",
      headerName: "Last Name",
      editable: false,
      flex: 0.7,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: "email",
      headerName: "Email Address",
      editable: false,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      editable: false,
      sortable: false,
      description: "This column has a value getter and is not sortable.",
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: "createdAt",
      headerName: "Created At",
      type: "date",
      editable: false,
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      valueFormatter: (params) => {
        return dateFormatter(params.value);
      },
    },
    {
      field: "role",
      headerName: "Role",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <Chip
            label={clickWrapUserRole[params.value]}
            color={chipTypeBasedOnRole[params.value]}
            variant="filled"
          />
        );
      },
    }, {
      field: "actions",
      headerName: "Action",
      editable: false,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        // console.log("params",params);
        return (
          <Button sx={{
            borderRadius: '10px'
          }} size="small" onClick={() => deleteUser(params.id)} variant="contained" color="error">Delete</Button>
        );
      },
    }
    /* {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        return (
          <Stack direction={"row"} spacing={1}>
            <IconButton aria-label="edit">
              <EditIcon />
            </IconButton>

            <IconButton aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Stack>
        );
      },
      flex: 0.4,
    }, */
  ];

  const toggleUserDialog = () => {
    setIsOpenUserModal(!isOpenUserModal);
  };

  const validateDataObject = () => {
    if (dataObject && dataObject.firstName === undefined) {
      showNotification("Please enter first name", notificationType.ERROR);
      return false;
    } else if (dataObject && dataObject.lastName === undefined) {
      showNotification("Please enter last name", notificationType.ERROR);
      return false;
    } else if (dataObject && dataObject.email === undefined) {
      showNotification("Please enter email address", notificationType.ERROR);
      return false;
    } else if (dataObject && dataObject.role === undefined) {
      showNotification("Please enter role", notificationType.ERROR);
      return false;
    } else if (dataObject && dataObject.password === undefined) {
      showNotification("Please enter password", notificationType.ERROR);
      return false;
    } else if (dataObject && dataObject.confirmPassword === undefined) {
      showNotification("Please enter confirm password", notificationType.ERROR);
      return false;
    } else if (dataObject && dataObject.mobileNumber === undefined) {
      showNotification("Please enter mobile number", notificationType.ERROR);
      return false;
    } else if (dataObject && dataObject.mobileNumber.length < 10) {
      showNotification(
        "Please enter valid mobile number",
        notificationType.ERROR
      );
      return false;
    } else if (
      dataObject &&
      dataObject.password !== dataObject.confirmPassword
    ) {
      showNotification(
        "Password & Confirm password must be same",
        notificationType.ERROR
      );
      return false;
    } else {
      return true;
    }
  };

  const onSaveUser = () => {
    if (validateDataObject()) {
      dispatch(addUser(dataObject));
      toggleUserDialog();
    }
  };

  const onChangeComponent = async (type, e) => {
    setDataObject(await addValuesInDataObject(dataObject, type, e));
  };

  return (
    <div style={outerListWrapper}>
      <ClickWrapDialog
        isShowServiceButton={false}
        dialogTitle={"User Configuration"}
        component={
          <UserForm
            onChangeComponent={onChangeComponent}
            dataObject={dataObject}
          />
        }
        onSaveModal={onSaveUser}
        onCloseModal={toggleUserDialog}
        isOpenDialog={isOpenUserModal}
      />
      <ClickWrapHeadRow
        onClickButton={toggleUserDialog}
        rowHeading={"Users Listing"}
        buttonText={"Add  New"}
      />
      <DataGrid
        disableSelectionOnClick
        hideFooterSelectedRowCount
        pageSize={pageSize}
        components={{
          NoRowsOverlay: ClickWrapEmptyTable,
        }}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={tablePaginationStaticList}
        pagination
        rows={userList}
        columns={columns}
        autoPageSize
      />
    </div>
  );
}

export default UsersListingScreen;
