import { combineReducers } from "redux";
import userReducer from "./user-reducer";
import currentUserReducer from "./current-user-reducer";
import cdPocReducer from "./cd-poc-reducer";

const rootReducer = combineReducers({
  users: userReducer,
  loggedUser: currentUserReducer,
  cdPocs: cdPocReducer,
});

export default rootReducer;
