import AxiosInstance from "../AxiosInstance";

class ClickWrapServices {
  updateClickWrap(i, j, data) {
    return AxiosInstance.put("/api/v1/click-wrap/" + j + "?j=" + i, data);
  }

  getClickWrapFormStatus(id) {
    return AxiosInstance.get("/api/v1/click-wrap/accept/" + id);
  }

  acceptClickWrapTerms(id) {
    return AxiosInstance.post("/api/v1/click-wrap/accept/" + id);
  }

  getFileBase64DataAfterClientSubmit(objectId) {
    return AxiosInstance.get("/api/v1/click-wrap/" + objectId);
  }

  postSignedDocument(objectId, data) {
    return AxiosInstance.post("/api/v1/click-wrap/signed/" + objectId, data);
  }
  getClickWrapDataFromCDPOc(objectId) {
    return AxiosInstance.get(
      "/api/v1/click-wrap/getClickwrap/"+ objectId
    );
  }

  getClientSignedInformation(objectId) {
    return AxiosInstance.get(
      "/api/v1/click-wrap/" + objectId + "?iscdpoc=true"
    );
  }
}

export default new ClickWrapServices();
