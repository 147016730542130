import React, { useEffect, useState } from "react";
import { Container, Grid, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ClickWrapButton from "../components/click-wrap-button";
import { APP_COLORS, notificationType } from "../constants/color-constant";
import UserDataService from "../redux/services/user-data-service";
import { useDispatch } from "react-redux";
import { setCurrentUser } from "../redux/actions/current-user-actions";
import { useNavigate } from "react-router-dom";
import { showNotification } from "../utilities/common-utility-functions";
import { cwMessages } from "../constants/message-constants";

function LoginPage() {
  const loginPageStylesObject = loginPageStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (localStorage.getItem("userToken")) {
      navigate("/");
    }
  }, []);

  const onClickLogin = () => {
    const dataObject = {
      email: emailAddress,
      password,
    };

    if (emailAddress.length === 0) {
      showNotification(cwMessages.ENTER_EMAIL, notificationType.SUCCESS);
    } else if (password.length === 0) {
      showNotification(cwMessages.ENTER_PASSWORD, notificationType.ERROR);
    } else {
      UserDataService.loginUser(dataObject)
        .then((response) => {
          if (response && response.status === 200) {
            dispatch(setCurrentUser(response.data.data)).then(async (e) => {
              await localStorage.setItem("userToken", response.data.data.token);
              showNotification(
                cwMessages.LOGIN_SUCCESSFUL,
                notificationType.SUCCESS
              );
              setTimeout(() => {
                navigate("/");
              }, 2000);
            });
          } else {
            showNotification(
              cwMessages.COMMON_ERROR_MESSAGE,
              notificationType.ERROR
            );
          }
        })
        .catch((e) => {
          showNotification(e?.response?.data?.message, notificationType.ERROR);
        });
    }
  };

  return (
    <Container disableGutters maxWidth="false">
      <Grid container height={"100vh"}>
        <Grid
          container
          xs={4}
          item
          className={loginPageStylesObject.outerLoginContainer}
        >
          <Grid
            container
            spacing={4}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Grid item>
              <Typography variant={"h4"}>Login</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="email-basic"
                fullWidth
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
                size={"small"}
                label="Email Address"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="password-basic"
                size={"small"}
                fullWidth
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={"password"}
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <ClickWrapButton
                buttonText={"Login"}
                size={"large"}
                variant="contained"
                onClick={onClickLogin}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={8}
          style={{
            background:
              "linear-gradient(68deg, rgba(2,0,36,1) 0%, rgba(13,71,161,1) 0%, rgba(33,150,243,1) 100%)",
          }}
        />
      </Grid>
    </Container>
  );
}

const loginPageStyles = makeStyles((theme) => ({
  outerLoginContainer: {
    display: "flex",
    backgroundColor: APP_COLORS.BACKGROUND_COLOR,
    alignItems: "center",
    justifyContent: "center",
    padding: 40,
  },
}));

export default LoginPage;
