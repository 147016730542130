import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { CD_POC_FORM_STRUCTURE_LIST } from "../constants/click-wrap-form-constants";
import { getCommonComponent } from "../utilities/common-utility-functions";
import PropTypes from "prop-types";

function CDPocForm({ dataObject, onChangeComponent }) {
  const [activeFields, setActiveFields] = useState([]);

  useEffect(() => {
    const newList = CD_POC_FORM_STRUCTURE_LIST.filter((e) => {
      if (
        Object.keys(dataObject).length > 0 &&
        dataObject?.typesOfServices !== {}
      ) {
        return (
          e.showIn.includes(dataObject?.typesOfServices) ||
          e.showIn.length === 0
        );
      } else {
        return e.showIn.length === 0;
      }
    });

    var filterListForSupportAndTraining = newList.filter((e)=>{
      if(dataObject['SupportHours']=='YES' ){
        return newList
      }else{
        if((e["name"] != "noOfHoursForSupport" && e["name"] != "periodInMonth" && e["name"] != "supportFees")){
          return true
        }        
      }
      // if(dataObject['onTimeTraining']=='YES'){
      //   return newList
      // }else{
      //   if((e["name"] != "trainingFees") ){
      //     return true
      //   }
      // }
    })
    console.log('dataObject',dataObject);
    console.log('filterListForSupportAndTraining',filterListForSupportAndTraining);

    setActiveFields(filterListForSupportAndTraining);
  }, [dataObject]);

  return (
    <Grid alignItems={"center"} justifyContent={"center"} container>
      <Grid container spacing={2}>
        {activeFields.map((element) => {
          return (
            <Grid key={element.name} xs={12} item>
              {getCommonComponent(
                element.type,
                element,
                dataObject,
                onChangeComponent
              )}
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}

CDPocForm.propTypes = {
  dataObject: PropTypes.object,
  onChangeComponent: PropTypes.func,
};

export default CDPocForm;
