import React, { useEffect } from "react";
import { Grid, Paper } from "@mui/material";
import { CUSTOMER_FORM_STRUCTURE_LIST } from "../constants/click-wrap-form-constants";
import { getCommonComponent } from "../utilities/common-utility-functions";
import ClickWrapHeading from "../components/click-wrap-heading";
import PropTypes from "prop-types";
import ClickWrapButton from "../components/click-wrap-button";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

function CustomerForm({
  dataObject,
  nationality,
  onChangeComponent,
  loadedAPIData,
  selectedEntityType,
}) {
  const onClickDownloadButton = () => {
    window.open(loadedAPIData?.sample_contract, "_blank");
  };

  useEffect(() => {}, [dataObject]);

  console.log(dataObject);

  return (
    <Grid alignItems={"center"} justifyContent={"center"} container>
      <Paper elevation={6} square style={{ padding: 20, margin: 20 }}>
        <Grid
          container
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
        >
          <Grid item>
            <ClickWrapHeading heading={"Customer Form"} />
          </Grid>

          <Grid item>
            <ClickWrapButton
              startIcon={<CloudDownloadIcon />}
              onClick={onClickDownloadButton}
              buttonText={"Agreement"}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} direction={"column"}>
          {CUSTOMER_FORM_STRUCTURE_LIST.filter((e) => {
            return e.showIn.includes(selectedEntityType);
          }).map((element) => {
            return (
              <Grid key={element.name} item>
                {getCommonComponent(
                  element.type,
                  element,
                  dataObject,
                  onChangeComponent,
                  nationality
                )}
              </Grid>
            );
          })}
        </Grid>
      </Paper>
    </Grid>
  );
}

CustomerForm.propTypes = {
  dataObject: PropTypes.object,
  onChangeComponent: PropTypes.func,
  loadedAPIData: PropTypes.object,
  selectedEntityType: PropTypes.string,
};

export default CustomerForm;
