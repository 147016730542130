import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import ClickWrapEmptyTable from "../../components/empty-components/click-wrap-empty-table";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import ClickWrapHeadRow from "../../components/click-wrap-head-row";
import ClickWrapDialog from "../../components/click-wrap-dialog";
import CDPocForm from "../../forms/cd-poc-form";
import {
  addCDPoc,
  approveOrReject,
  getCDPoCListing,
} from "../../redux/actions/cd-poc-actions";
import { useDispatch, useSelector } from "react-redux";
import {
  clickWrapUserRole,
  CW_ORGANISATION_TYPE,
  tablePaginationStaticList,
} from "../../constants/click-wrap-constants";
import {
  addValuesInDataObject,
  dateFormatter,
  showNotification,
} from "../../utilities/common-utility-functions";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CustomerFormStaticTable from "./customer-form-static-table";
import { outerListWrapper } from "../../constants/common-style-object";
import ClickWrapApproveRejectDialog from "../../components/click-wrap-approve-reject-dialog";
import { notificationType } from "../../constants/color-constant";
import { CD_POC_FORM_STRUCTURE_LIST } from "../../constants/click-wrap-form-constants";
import OAuthImplicit from "../../redux/services/oauth-implimentation";
import DocuSign from "../../redux/services/docusign";

function CdPocListingScreen() {
  const dispatch = useDispatch();

  const loggedUserData = useSelector((state) => state.loggedUser);

  const [addClientDialog, setAddClientDialog] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(10);
  const [dataObject, setDataObject] = useState({});
  const [approveOrRejectReason, setApproveOrRejectReason] = useState("");

  const [selectedDataObject, setSelectedDataObject] = useState({});
  const [viewCdPOCDialog, setViewPOCDialog] = useState(false);

  const cdPocsList = useSelector((state) => state.cdPocs);
  const oAuthImplicit = new OAuthImplicit();
  const docuSign = new DocuSign();

  useEffect(() => {
    dispatch(getCDPoCListing());
  }, []);
  const onChangeComponent = async (type, e) => {
    setDataObject(await addValuesInDataObject(dataObject, type, e));
  };

  const columns = [
    { field: "id", headerName: "ID", width: 250 },
    {
      field: "customerName",
      headerName: "Customer Name",
      editable: false,
      flex: 1,
    },
    {
      field: "emailIDOfCustomerWhereContractToShare",
      headerName: "Email Of Client",
      editable: false,
      flex: 1,
    },
    {
      field: "createdBy",
      headerName: "CD POC",
      editable: false,
      flex: 1,
      valueGetter: (params) => {
        return `${params.row?.createdBy?.firstName || ''} ${params.row?.createdBy?.lastName || ''}`}
    },
    {
      field: "selectedTatvicEntity",
      headerName: "Selected Entity",
      editable: false,
      flex: 1,
      valueFormatter: (params) => {
        return CW_ORGANISATION_TYPE.filter(
          (element) => element.label === params.value
        )[0].name;
      },
    },
    // {
    //   field: "typesOfServices",
    //   headerName: "Types Of Services",
    //   editable: false,
    //   flex: 1,
    //   valueFormatter: (params) => {
    //     return params.value.map(
    //       (e) =>
    //         CW_TYPES_OF_SERVICES.filter((element) => element.name === e)[0]
    //           .label
    //     );
    //   },
    // },
    {
      field: "status",
      headerName: "Status",
      editable: false,
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      editable: false,
      flex: 1,
      valueFormatter: (params) => {
        return dateFormatter(params.value);
      },
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        return (
          <Stack direction={"row"} spacing={1}>
            <IconButton
              onClick={(e) => {
                const data = params.api.getRow(params.id);
                setSelectedDataObject(data);
                toggleViewCDPOCDialog();
              }}
              aria-label="edit"
            >
              <VisibilityIcon />
            </IconButton>
          </Stack>
        );
      },
      flex: 0.1,
    },
  ];

  const toggleAddClientDialog = () => {
    setAddClientDialog(!addClientDialog);
  };

  const onSaveClient = () => {
    let requiredFields = CD_POC_FORM_STRUCTURE_LIST.filter(
      (e) => e.showIn.length === 0
    );
    if (dataObject?.typesOfServices) {
      requiredFields = CD_POC_FORM_STRUCTURE_LIST.filter(
        (e) =>
          e.showIn.length === 0 ||
          e.showIn.includes(dataObject?.typesOfServices)
      );
    }

    var sAndHValidation = requiredFields.filter((items)=>{
      if(dataObject['SupportHours']=="NO"){
        if(items['name']!="periodInMonth" && items['name']!="supportFees" && items['name']!="noOfHoursForSupport"){
          return true
        }
      }else{
        return true
      }
    })
    console.log('requiredFields',requiredFields);

    if (Object.keys(dataObject).length === sAndHValidation.length) {
      dispatch(addCDPoc(dataObject)).then(() => {
        toggleAddClientDialog();
      });
    } else {
      const finalList = sAndHValidation
        .filter((e) => !Object.keys(dataObject).includes(e.name))
        .map((element) => {
          return element.label ? element.label : element.disabledOptionCaption;
        })
        .filter((e) => e !== undefined);

      showNotification(
        "Please enter " + finalList?.toString(),
        notificationType.ERROR
      );
    }
  };

  const toggleViewCDPOCDialog = () => {
    setViewPOCDialog(!viewCdPOCDialog);
  };

  const onCloseViewCDPOCDialog = () => {
    setSelectedDataObject({});
    setViewPOCDialog(false);
  };

  const onClickApproveOrReject = (key) => {
    const data = {
      status: key,
      comment: approveOrRejectReason,
    };
    if (approveOrRejectReason.toString().trim().length === 0) {
      showNotification("Please enter proper reason", notificationType.ERROR);
    } else {
      dispatch(
        approveOrReject(localStorage.getItem("clickWrapTempId"), data)
      ).then(() => {
        onCloseViewCDPOCDialog();
        showNotification("Data updated successfully", notificationType.SUCCESS);
      });
    }
  };

  const onClickSendEnvelope = async (data) => {
    // localStorage.setItem(
    //   "filePDF",
    //   data.unsigned_pdf
    // );

    oAuthImplicit.startLogin();
    console.log("data", data);

    // localStorage.setItem("clickWrapID", query.get("j"));
    // await oAuthImplicit.receiveHash(window.location.hash).then(() => {
    //   docuSign.sendEnvelope().then((response) => {
    //     localStorage.setItem("envelopeId", response?.envelopeId);
    //     docuSign
    //       .openRecipientPreview(response?.envelopeId)
    //       .then((recipientPreview) => {
    //         localStorage.setItem("isFlowCompleted", "NOT_DONE");
    //         // window.open(recipientPreview?.url);
    //         console.log("url",recipientPreview?.url);
    //       });
    //   });
    // });
  }

  const onClickClientSign = async (key) => {
    // localStorage.setItem(
    //   "filePDF",
    //   data.unsigned_pdf
    // );
    // oAuthImplicit.startLogin();
    console.log("client signed");
    const data = {
      status: "client_signed",
      // comment: approveOrRejectReason,
    };
   
    dispatch(
      approveOrReject(localStorage.getItem("clickWrapTempId"), data),
    );
    
    // localStorage.setItem("clickWrapID", query.get("j"));
    // await oAuthImplicit.receiveHash(window.location.hash).then(() => {
    //   docuSign.sendEnvelope().then((response) => {
    //     localStorage.setItem("envelopeId", response?.envelopeId);
    //     docuSign
    //       .openRecipientPreview(response?.envelopeId)
    //       .then((recipientPreview) => {
    //         localStorage.setItem("isFlowCompleted", "NOT_DONE");
    //         // window.open(recipientPreview?.url);
    //         console.log("url",recipientPreview?.url);
    //       });
    //   });
    // });
  }
  return (
    <div style={outerListWrapper}>
      <ClickWrapHeadRow
        onClickButton={toggleAddClientDialog}
        rowHeading={"Index"}
        buttonText={"Add New"}
      />
      {loggedUserData.role === clickWrapUserRole.LEGAL_TEAM ||
        clickWrapUserRole.FINANCE_TEAM ||
        clickWrapUserRole.SUPER_ADMIN ? (
        <ClickWrapApproveRejectDialog
          dialogTitle={"CD POC Details"}
          component={
            <CustomerFormStaticTable dataObject={selectedDataObject} />
          }
          isShowServiceButton={false}
          approveOrRejectReason={approveOrRejectReason}
          onChangeApproveOrReject={(e) =>
            setApproveOrRejectReason(e.target.value)
          }
          onClickApprove={() => onClickApproveOrReject("approve")}
          onClickReject={() => onClickApproveOrReject("reject")}
          onClickSendEnvelope={() => onClickSendEnvelope(selectedDataObject)}
          onClickClientSign={() => onClickClientSign(selectedDataObject)}
          onCloseModal={onCloseViewCDPOCDialog}
          isOpenDialog={viewCdPOCDialog}
          selectedDataObject={selectedDataObject}
        />
      ) : (
        <ClickWrapDialog
          dialogTitle={"CD POC Details"}
          component={
            <CustomerFormStaticTable dataObject={selectedDataObject} />
          }
          isShowServiceButton={false}
          onCloseModal={onCloseViewCDPOCDialog}
          isOpenDialog={viewCdPOCDialog}
        />
      )}
      <ClickWrapDialog
        isShowServiceButton={false}
        dialogTitle={"CD POC Configuration"}
        component={
          <CDPocForm
            dataObject={dataObject}
            onChangeComponent={onChangeComponent}
          />
        }
        onSaveModal={onSaveClient}
        onCloseModal={toggleAddClientDialog}
        isOpenDialog={addClientDialog}
      />
      <DataGrid
        disableSelectionOnClick
        hideFooterSelectedRowCount
        pageSize={pageSize}
        components={{
          NoRowsOverlay: ClickWrapEmptyTable,
        }}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={tablePaginationStaticList}
        pagination
        rows={cdPocsList}
        columns={columns}
        autoPageSize
      />
    </div>
  );
}

export default CdPocListingScreen;
