import React from "react";
import { getCommonComponent } from "../utilities/common-utility-functions";
import { Grid } from "@mui/material";
import { CUSTOMER_FORM_LIST } from "../constants/click-wrap-form-constants";
import PropTypes from "prop-types";

function UserForm({ dataObject, onChangeComponent }) {
  return (
    <Grid alignItems={"center"} justifyContent={"center"} container>
      <Grid container spacing={2} direction={"column"}>
        {CUSTOMER_FORM_LIST.map((element) => {
          return (
            <Grid key={element.id} item>
              {getCommonComponent(
                element.type,
                element,
                dataObject,
                onChangeComponent
              )}
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}

UserForm.propTypes = {
  dataObject: PropTypes.object,
  onChangeComponent: PropTypes.func,
};

export default UserForm;
