import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import DocuSign from "../redux/services/docusign";
import OAuthImplicit from "../redux/services/oauth-implimentation";
import { useDispatch } from "react-redux";
import { postSignedDocument } from "../redux/actions/click-wrap-actions";
import {
  // addCDPoc,
  approveOrReject,
  // getCDPoCListing,
} from "../redux/actions/cd-poc-actions";

function EnvelopeSentPage() {
  const [currentMessageObject, setCurrentMessageObject] = useState({});

  const docuSign = new DocuSign();
  const authImplicit = new OAuthImplicit();
  const dispatch = useDispatch();

  useEffect(async () => {
    setCurrentMessageObject({
      heading: "Processing",
      description: "We are processing, Please wait for few minutes.",
    });
    // console.log("Here Called");
    await authImplicit.receiveHash(window.location.hash).then(() => {
      docuSign.sendEnvelope().then((response) => {
        localStorage.setItem("envelopeId", response?.envelopeId);
        const data = {
          status: "sent_envelope",
          envellope_id: response?.envelopeId,
          // comment: approveOrRejectReason,
        };

        dispatch(
          approveOrReject(localStorage.getItem("clickWrapTempId"), data),
        ).then(() => {
          // onCloseViewCDPOCDialog();
          // showNotification("Envelope sent successfully", notificationType.SUCCESS);
          document.location.href = "/";
        });
      });
    });
    // }

    // if (checkVariable === "NOT_DONE") {
    //   // docuSign.getEnvelope().then(async (response)=>{
    //   //   // localStorage.setItem("signed_pdf",response.resultsEnvelopeJson);
    //   //   postSignedDocument(localStorage.getItem("clickWrapID"), {
    //   //     envelope_id: localStorage.getItem("envelopeId"),
    //   //     signed_pdf:response.resultsEnvelopeJson
    //   //   });
    //   //   // console.log(response.resultsEnvelopeJson);
    //   // });
    // } else {
    //   console.log("Window Location Hash", window.location.hash);
    // }
  }, []);

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}>
      <Grid
        container
        direction={"column"}
        alignItems={"center"}
        justifyContent={"center"}>
        <Grid item>
          <Typography variant={"h3"}>
            {currentMessageObject?.heading}
          </Typography>
        </Grid>
        <Grid item>
          <Typography>{currentMessageObject?.description}</Typography>
        </Grid>
      </Grid>
    </div>
  );
}

EnvelopeSentPage.propTypes = {
  messageObject: PropTypes.object,
};

export default EnvelopeSentPage;
