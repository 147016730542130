// import * as React from "react";

// import { outerListWrapper } from "../../constants/common-style-object";


// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// import { Button, TextField } from "@mui/material";

// function TemplateEditScreen() {
//   const [agreement, setagreement] = React.useState('');
//   const [isUpdated, setIsUpdated] = React.useState(false);
//   const [agreementData,setAgreementData] = React.useState(`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec feugiat suscipit commodo. Vestibulum efficitur turpis in nisi ultrices vestibulum. Duis vitae feugiat urna. Aliquam pellentesque nunc ut lectus suscipit, a congue libero ullamcorper. Maecenas cursus placerat ex a accumsan. Donec non dui velit. Quisque nec mi arcu. Curabitur vestibulum aliquam quam, et blandit velit lacinia ac. Phasellus ullamcorper massa eget laoreet feugiat. Praesent arcu urna, semper nec pretium id, iaculis non massa. Fusce aliquam metus at ex tempor, ut euismod nisl consequat. Suspendisse et pulvinar justo.

//   Fusce condimentum at turpis ut tristique. Sed nec eleifend metus. Morbi vitae posuere lacus, eget semper ante. Curabitur euismod ut massa at ultricies. In rhoncus elementum nunc, at facilisis libero eleifend et. Donec gravida auctor sodales. Donec sed lectus sed dui consequat molestie eget vitae metus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nunc eleifend libero a metus vestibulum molestie. Donec pellentesque tortor neque, fringilla commodo ipsum gravida a. Sed sed libero sit amet mauris ultricies lobortis. Nulla ullamcorper nulla vitae nulla interdum, eu efficitur tortor vestibulum. Sed ac nibh lorem.

//   Etiam non lobortis nulla. Nulla ac elit condimentum, pellentesque quam sit amet, pharetra sem. Etiam a quam lorem. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce libero augue, mattis vel placerat quis, interdum vel dui. Sed tempor porta risus eu mattis. Aenean nec sagittis nulla. Praesent tincidunt nulla sed vulputate consequat.

//   Sed ipsum justo, sagittis at nisi nec, blandit aliquet diam. Quisque non magna ornare, vehicula urna porta, lobortis massa. Proin et eros vitae nunc tempus ornare eu vel turpis. Mauris aliquet euismod semper. Donec quam turpis, accumsan vitae nunc sed, sagittis volutpat ligula. Donec sed varius mi. Sed molestie, felis sit amet blandit porta, dolor neque congue mi, sed tincidunt turpis nisi nec lacus.

//   Cras blandit ultrices leo eget placerat. Vivamus rutrum, nunc in molestie varius, mauris nisl convallis ex, in vestibulum leo tortor quis velit. Pellentesque a dui tellus. Nulla a nisi id risus malesuada hendrerit eu sed lacus. Duis tempus, magna sed pulvinar venenatis, erat turpis euismod tortor, a commodo quam metus eu lectus. Morbi rhoncus enim quis mauris porttitor, non vehicula purus sodales. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nisi felis, bibendum ullamcorper ex id, suscipit tempus nulla. Integer nibh sapien, rhoncus et dolor vitae, ultricies cursus felis. Donec odio tellus, efficitur non dapibus vel, eleifend eget lorem. Cras ut enim vel eros euismod faucibus sed vitae quam.`);

//   const handleChange = (event) => {
//     setagreement(event.target.value);
//     setIsUpdated(false)
//   };


//   return (
//     <div style={outerListWrapper}>

//       <FormControl sx={{
//         width: '100%'
//       }}>
//         <InputLabel id="demo-simple-select-label">Select Agreement</InputLabel>
//         <Select
//           labelId="demo-simple-select-label"
//           id="demo-simple-select"
//           value={agreement}
//           label="Select Agreement"
//           onChange={handleChange}
//         >
//           <MenuItem value={"GMP Advertising Agreement"}>GMP Advertising Agreement</MenuItem>
//           <MenuItem value={"GCP"}>GCP</MenuItem>
//           <MenuItem value={"G Suite"}>G Suite</MenuItem>
//         </Select>
//         {
//           agreement.length == 0 || isUpdated ? null : <>
//             <TextField
//               sx={{
//                 marginTop: '30px',
//                 width: '100%'
//               }}
//               id="outlined-textarea"
//               label={agreement}
//               multiline
//               maxRows={16}
//               // focused="true"
//               value={agreementData}
//               onChange={(e) => setAgreementData(e.target.value)}
//             />

//             <Button type="submit" sx={{ marginTop: "10px" }} onClick={() => (setIsUpdated(true))} variant="contained">Update</Button></>
//         }
//         {
//           isUpdated ?  
//           <p>{agreement} is successfully updated.</p> : ""
//         }
//       </FormControl>

//     </div>
//   );
// }

// export default TemplateEditScreen;


import * as React from "react";

import { outerListWrapper } from "../../constants/common-style-object";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Ga4Agreement from "../../forms/agreements/Ga4Agreement";
import GmpAgreement from "../../forms/agreements/GMPAgreemnt";
import GCPAgreement from "../../forms/agreements/GCPAgreement";

function TemplateEditScreen() {
  const [agreement, setAgreement] = React.useState('')
  const [entity, setEntity] = React.useState('')

  return (
    <div style={outerListWrapper}>
      <FormControl component={'form'} sx={{
        width: '100%'
      }}>
        <InputLabel id="select_agreement">Select Agreement</InputLabel>
        <Select
          labelId="select_agreement"
          id="select_agreement"
          value={agreement}
          label="Select Agreement"
          onChange={(event)=>setAgreement(event.target.value)}
        >
          <MenuItem value={"GA4 agreement"}>GA4 agreement</MenuItem>
          <MenuItem value={"GCP"}>GCP</MenuItem>
          <MenuItem value={"GMP"}>GMP</MenuItem>
          <MenuItem value={"CRO"}>CRO</MenuItem>
        </Select>
      </FormControl>
      <FormControl component={'form'} sx={{
        width: '100%',
        marginTop:'20px'
      }}>
        <InputLabel id="entity_type">Select Entity</InputLabel>
        <Select
          labelId="entity_type"
          id="entity_type"
          value={entity}
          label="Select Entity"
          onChange={(event)=>setEntity(event.target.value)}
        >
          <MenuItem value={"COMPANY"}>COMPANY</MenuItem>
          <MenuItem value={"PARTNERSHIP_FIRM"}>PARTNERSHIP_FIRM</MenuItem>
          <MenuItem value={"INDIVIDUAL"}>INDIVIDUAL</MenuItem>
        </Select>
      </FormControl>
      {agreement == 'GA4 agreement' ?<Ga4Agreement data={{entity}}/>:
      agreement == "GMP"?<GmpAgreement data={{entity}} />
      :agreement =="CRO"?<Ga4Agreement data={{entity}}/>:
      agreement=="GCP"?<GCPAgreement data={{entity}}/>:""
      }
    </div >
  );
}

export default TemplateEditScreen;
