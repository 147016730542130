import React from "react";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";

function ClickWrapHeading({ heading }) {
  return (
    <Typography mb={2} variant={"h4"}>
      {heading}
    </Typography>
  );
}

ClickWrapHeading.propTypes = {
  heading: PropTypes.string.isRequired,
};

export default ClickWrapHeading;
