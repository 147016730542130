import * as ActionConstants from "../constants/redux-constants";

const userReducer = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionConstants.GET_ALL_USERS:
      return payload;
  }
  return state;
};

export default userReducer;
