import React, { useEffect } from "react";
import { useQuery } from "../utilities/common-utility-functions";
import { Grid, Typography } from "@mui/material";
import { getFileBase64DataAfterClientSubmit } from "../redux/actions/click-wrap-actions";
import OAuthImplicit from "../redux/services/oauth-implimentation";
import { useDispatch } from "react-redux";

function FinalApprovalPage() {
  const query = useQuery();
  const oAuthImplicit = new OAuthImplicit();
  const dispatch = useDispatch();

  useEffect(async () => {
    localStorage.setItem("clickWrapID", query.get("j"));
    await dispatch(getFileBase64DataAfterClientSubmit(query.get("j"))).then(
      (documentResult) => {
        localStorage.setItem("filePDF", documentResult.data.data[0].signed_pdf);
        localStorage.setItem("isAdminApproval", "true");
        // oAuthImplicit.startLogin();
      }
    );
  }, []);

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Grid
        container
        direction={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Grid item>
          <Typography variant={"h3"}>{"Processing"}</Typography>
        </Grid>
        <Grid item>
          <Typography>
            {"We are processing, Please wait for few minutes."}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default FinalApprovalPage;
