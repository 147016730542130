import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import PropTypes from "prop-types";

function ClickWrapRadioComponent({ options, legend, name, value, onChange ,nationality}) {
  return (
    <FormControl sx={{ width: "100%", display:(name=="isRegisteredGSTEntity" && nationality!="NATIONAL")? "none":"block" }}>
      <FormLabel component="legend">{legend}</FormLabel>
      <RadioGroup row name={name} value={value} onChange={onChange}>
        {options?.map((e) => {
          return (
            <FormControlLabel
              value={e.label}
              key={e.label}
              control={<Radio />}
              label={e.name}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}

ClickWrapRadioComponent.propTypes = {
  options: PropTypes.array.isRequired,
  legend: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default ClickWrapRadioComponent;
