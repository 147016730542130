export const cdPOCMapping = {
  _id: "User ID",
  customerName: "Customer Name",
  entityType: "Entity Type",
  currencyOptedForBilling: "Currency Opted For Billing",
  selectedTatvicEntity: "Selected Tatvic Entity",
  typesOfServices: "Types Of Services",
  effectiveDateOfService: "Effective Date Of Service",
  currentDayDate: "Current Day Date",
  emailIDOfCustomerWhereContractToShare:
    "Email ID Of Customer Where Contract To Share",
  platFormFeeDBM: "Platform Fee DBM",
  minMonthlyFeeApplicableToCustomer:
    "Minimum Monthly Fee Application To Customer",
  monthlyDBMServiceFeesForCustomer: "Monthly DBM Service Fees For Customer",
  monthlyCampaignManagerExchangeRate: "Monthly Campaign Manager Exchange Rate",
  minFeesPerMonthInPercentage: "Minimum Fees Per Month In Percentage",
  initialSetupFeesCampaignManager: "Initial Setup Fees Campaign Manager",
  monthlyCPMRates0to1Cr: "Monthly CPM Rate 0 to 1 Cr",
  monthlyCPMRates1to25Cr: "Monthly CPM Rate 1 to 2.5 Cr",
  monthlyCPMRates5to10Cr: "Monthly CPM Rate 5 to 10 Cr",
  monthlyCPMRates10to20Cr: "Monthly CPM Rate 10 to 20 Cr",
  monthlyCPMRatesMoreThan20Cr: "Monthly CPM Rate More Than 20 Cr",
  campaignManagerServiceFeesDCMCPM: "Campaign Manager Service Fees DCM CPM",
  campaignManagerServiceFeesVideoCPM: "Campaign Manager Service Fees Video CPM",
  campaignManagerServiceFeesAdvDisplayUpCharge:
    "Campaign Manager Service Fees Adv Display Up Charge",
  campaignManagerServiceFeesAdvDisplayCPC:
    "Campaign Manager Service Fees Adv Display CPC",
  adLimitKbs: "Ad Limit In Kbs ",
  adServingRateFormatFollowingRate: "Ad Serving Rate Format Following Rate",
  monthlyServiceFeeForClicks: "Monthly Service Fee For Clicks",
  minServiceFeesDCM: "Minimum Service Fees DCM",
  rfpModuleSetupFees: "RFP Module Setup Fees",
  rfpModuleRate: "RFP Module Rate",
  basicFloodLight: "Basic Flood Light",
  dynamicFloodLight: "Dynamic Flood Light",
  postBillingCreditLimit: "Post Billing Credit Limit",
  totalOfBilledPlusUnBilled: "Total Of Billed Plus Unbilled",
  doCustomerShallHaveMakePayment: "Do Customer Share Have to Make Payment",
  sa360InitialSetupFee: "SA360 Initial Setup Fee",
  sa360MonthlyPercentage: "SA360 Monthly Percentage",
  gSuiteNumberOfUsersByClient: "Gsuite Number OF Users By Client",
  gSuitePricePerMonth: "Gsuite Price Per Month",
  gSuiteAdditionalUserCharge: "Gsuite Additional User Charge",
  gSuiteAdditionalStorageCharge: "Gsuite Additional Storage Charge",
  gSuiteYearlyCharge: "Gsuite Yearly Charge",
  indicativeBudgetLimit: "Indicative Budget Limit",
  oneTimeInvoice:" One Time Invoice",
  statementOfWorkLink: "SOW Spreadsheet Link",
  nationality: "Nationality",
  SupportHours: "Support Hours",
  noOfHoursForSupport: "No Of Hours For Support",
  periodInMonth: "Period In Month",
  supportFees: "Support Fees",
  trainingFees: "Training Fees",
  createdBy: "Created By",
  updatedBy: "Updated By",
  updatedAt: "Updated At",
  createdAt: "Created At",
  status: "Status",
  __v: "Version",
  id: "Id",
};

export const clickWrapClientMapping = {
  _id: "Client Id",
  isAccepted: "Is Accepted",
  isSubmitted: "Is Client Submitted",
  clientEmailAddress: "Client Email Address",
  nameOfLegalEntity: "Name Of Legal Entity",
  registeredOfficeAddress: "Registered Office Address",
  corporateOfficeAddress: "Corporate Office Address",
  isYourCorporateOfficeSameAsRegistered: "Same Address",
  corporateIdentificationNumber: "Corporate Identification Number",
  nameOfCountry: "Name Of Country",
  countryWhenPartnershipDone: "Partnership Country",
  soleProprietor: "Sole Proprietor",
  panCard: "PAN Card",
  passportNumber: "Passport Number",
  drivingLicenseNumber: "DL Number",
  emailAddressForInvoice: "Invoice Email",
  personOfContactForCorrespondence: "Correspondence Contact",
  addressWhereAnyCorrespondenceToBeShared: "Correspondence Address",
  emailIdOfCorrespondence: "Correspondence Email",
  contactOfCorrespondence: "Contact Of Correspondence",
  nameOfAuthorizedSignatory: "Name Of Authorised Signatory",
  designationOfTheAuthorizedSignatory: "Designation Of Authorised Signatory",
  emailIdOfAuthorizedSignatory: "Email Of Authorised Signatory",
  contactNoOfAuthSignatory: "Contact Of Signatory",
  isRegisteredGSTEntity: "Registered For GST",
  gstRegisteredNumber: "GST Reg Number",
  clientEngagedIn: "Client Engaged In",
  envelopId: "Envelop Id",
  unsigned_pdf: "Unsigned PDF",
  signed_pdf: "Signed PDF",
  cdPoc: "CD POC",
  additionalSupportServices: "Additional Support Service",
  authorizedSignatoryDocument: "Signatory Document",
  govtApprovedIDProofDocument: "Govt Approved Document",
  createdBy: "Created By",
  updatedBy: "Updated By",
  updatedAt: "Updated At",
  createdAt: "Created At",
  __v: "Version",
};
