
import * as React from "react";

import { Button, TextField } from "@mui/material";
import axios from "axios";
import { async } from "regenerator-runtime";
import AxiosInstance from "../../redux/AxiosInstance";
import { useEffect } from "react";

function Ga4Agreement(props) {

    var [templateId, setTemplateId] = React.useState("")
    var {entity} = props.data
    var [ga4Agreement,setGa4Agreement] = React.useState(
        {
            entityType:entity,
            introductionSection:'',
            recitalSection:'',
            clouse1ScopeoofService:'',
            clouse2InvoicePayment:'',
            clouse3confidentiality:'',
            clouse4representations:'',
            clouse5limitations:'',
            clouse6ipr:'',
            clouse7termination:'',
            clouse8settlement:'',
            clouse9miscellaneous:'',
            annexureSection:'',
            footer:'',
            header:''
        }
    )
   async function create(e){
        e.preventDefault()
       
       await AxiosInstance.post('/api/v1/ga4agreement', {
            entityType:entity,
            introductionSection:ga4Agreement.introductionSection,
            recitalSection:ga4Agreement.recitalSection,
            clouseSection:{
                clouse1ScopeoofService:ga4Agreement.clouse1ScopeoofService,
                clouse2InvoicePayment:ga4Agreement.clouse2InvoicePayment,
                clouse3confidentiality:ga4Agreement.clouse3confidentiality,
                clouse4representations:ga4Agreement.clouse4representations,
                clouse5limitations:ga4Agreement.clouse5limitations,
                clouse6ipr:ga4Agreement.clouse6ipr,
                clouse7termination:ga4Agreement.clouse7termination,
                clouse8settlement:ga4Agreement.clouse7termination,
                clouse9miscellaneous:ga4Agreement.clouse9miscellaneous
            },
            annexureSection:ga4Agreement.annexureSection,
            footer:ga4Agreement.footer,
            header:ga4Agreement.header
        }).then(res=>console.log("data is send successfully")).catch(e=>console.log(e.message))
    }

    async function update(e) {
        e.preventDefault()

        await AxiosInstance.put('/api/v1/ga4agreement/?id=' + templateId, {
            entityType: entity,
            introductionSection: ga4Agreement.introductionSection,
            recitalSection: ga4Agreement.recitalSection,
            clouseSection: {
                clouse1ScopeoofService: ga4Agreement.clouse1ScopeoofService,
                clouse2InvoicePayment: ga4Agreement.clouse2InvoicePayment,
                clouse3confidentiality: ga4Agreement.clouse3confidentiality,
                clouse4representations: ga4Agreement.clouse4representations,
                clouse5limitations: ga4Agreement.clouse5limitations,
                clouse6ipr: ga4Agreement.clouse6ipr,
                clouse7termination: ga4Agreement.clouse7termination,
                clouse8settlement: ga4Agreement.clouse7termination,
                clouse9miscellaneous: ga4Agreement.clouse9miscellaneous
            },
            annexureSection: ga4Agreement.annexureSection,
            footer: ga4Agreement.footer,
            header: ga4Agreement.header
        }).then(res => console.log("data is send successfully")).catch(e => console.log(e.message))
    }
    useEffect(()=>{
        {
            entity != ''?  AxiosInstance.get(`/api/v1/ga4agreement/${entity}`).then((res)=>{
                console.log("res",res);
                        var {data} = res.data
                        setTemplateId(res.data.data._id)

                        setGa4Agreement({
                            entityType:entity,
                            introductionSection:data.introductionSection,
                            recitalSection:data.recitalSection,
                            clouse1ScopeoofService:data.clouseSection.clouse1ScopeoofService,
                            clouse2InvoicePayment:data.clouseSection.clouse2InvoicePayment,
                            clouse3confidentiality:data.clouseSection.clouse3confidentiality,
                            clouse4representations:data.clouseSection.clouse4representations,
                            clouse5limitations:data.clouseSection.clouse5limitations,
                            clouse6ipr:data.clouseSection.clouse6ipr,
                            clouse7termination:data.clouseSection.clouse7termination,
                            clouse8settlement:data.clouseSection.clouse8settlement,
                            clouse9miscellaneous:data.clouseSection.clouse9miscellaneous,
                            annexureSection:data.annexureSection,
                            footer:data.footer,
                            header:data.header
                        })

                    }).catch(err=>console.log(err)):null
        }
        
    },[entity])
    return (<>
        <form>
            <TextField
                sx={{
                    marginTop: '30px',
                    width: '100%'
                }}
                id="outlined-textarea"
                label={"Introduction"}
                multiline
                maxRows={16}
                // focused="true"
                value={ga4Agreement.introductionSection}
                onChange={(e)=>setGa4Agreement({...ga4Agreement,introductionSection:e.target.value})}
            />
            <TextField
                sx={{
                    marginTop: '30px',
                    width: '100%'
                }}
                id="outlined-textarea"
                label={"Recital"}
                multiline
                maxRows={16}
                // focused="true"
                value={ga4Agreement.recitalSection}
                onChange={(e)=>setGa4Agreement({...ga4Agreement,recitalSection:e.target.value})}
            />
             <TextField
                sx={{
                    marginTop: '30px',
                    width: '100%'
                }}
                id="outlined-textarea"
                label={"Clouse 1: Scope of Service"}
                multiline
                maxRows={16}
                // focused="true"
                value={ga4Agreement.clouse1ScopeoofService}
                onChange={(e)=>setGa4Agreement({...ga4Agreement,clouse1ScopeoofService:e.target.value})}
            />
            <TextField
                sx={{
                    marginTop: '30px',
                    width: '100%'
                }}
                id="outlined-textarea"
                label={"Clouse 2: Invoice Payment"}
                multiline
                maxRows={16}
                // focused="true"
                value={ga4Agreement.clouse2InvoicePayment}
                onChange={(e)=>setGa4Agreement({...ga4Agreement,clouse2InvoicePayment:e.target.value})}
            />
            <TextField
                sx={{
                    marginTop: '30px',
                    width: '100%'
                }}
                id="outlined-textarea"
                label={"Clouse 3: CONFIDENTIALITY "}
                multiline
                maxRows={16}
                // focused="true"
                value={ga4Agreement.clouse3confidentiality}
                onChange={(e)=>setGa4Agreement({...ga4Agreement,clouse3confidentiality:e.target.value})}
            />
            <TextField
                sx={{
                    marginTop: '30px',
                    width: '100%'
                }}
                id="outlined-textarea"
                label={"Clouse 4: REPRESENTATIONS "}
                multiline
                maxRows={16}
                // focused="true"
                value={ga4Agreement.clouse4representations}
                onChange={(e)=>setGa4Agreement({...ga4Agreement,clouse4representations:e.target.value})}
            />
            <TextField
                sx={{
                    marginTop: '30px',
                    width: '100%'
                }}
                id="outlined-textarea"
                label={"Clouse 5: LIMITATIONS "}
                multiline
                maxRows={16}
                // focused="true"
                value={ga4Agreement.clouse5limitations}
                onChange={(e)=>setGa4Agreement({...ga4Agreement,clouse5limitations:e.target.value})}
            />
            <TextField
                sx={{
                    marginTop: '30px',
                    width: '100%'
                }}
                id="outlined-textarea"
                label={"Clouse 6: IPR "}
                multiline
                maxRows={16}
                // focused="true"
                value={ga4Agreement.clouse6ipr}
                onChange={(e)=>setGa4Agreement({...ga4Agreement,clouse6ipr:e.target.value})}
            />
            <TextField
                sx={{
                    marginTop: '30px',
                    width: '100%'
                }}
                id="outlined-textarea"
                label={"Clouse 7: Termination"}
                multiline
                maxRows={16}
                // focused="true"
                value={ga4Agreement.clouse7termination}
                onChange={(e)=>setGa4Agreement({...ga4Agreement,clouse7termination:e.target.value})}
            />
            <TextField
                sx={{
                    marginTop: '30px',
                    width: '100%'
                }}
                id="outlined-textarea"
                label={"Clouse 8: SETTLEMENT "}
                multiline
                maxRows={16}
                // focused="true"
                value={ga4Agreement.clouse8settlement}
                onChange={(e)=>setGa4Agreement({...ga4Agreement,clouse8settlement:e.target.value})}
            />
            <TextField
                sx={{
                    marginTop: '30px',
                    width: '100%'
                }}
                id="outlined-textarea"
                label={"Clouse 9: MISCELLANEOUS "}
                multiline
                maxRows={16}
                // focused="true"
                value={ga4Agreement.clouse9miscellaneous}
                onChange={(e)=>setGa4Agreement({...ga4Agreement,clouse9miscellaneous:e.target.value})}
            />
           
            <TextField
                sx={{
                    marginTop: '30px',
                    width: '100%'
                }}
                id="outlined-textarea"
                label={"Annexure"}
                multiline
                maxRows={16}
                // focused="true"
                value={ga4Agreement.annexureSection}
                onChange={(e)=>setGa4Agreement({...ga4Agreement,annexureSection:e.target.value})}
            />
            <TextField
                sx={{
                    marginTop: '30px',
                    width: '100%'
                }}
                id="outlined-textarea"
                label={"Footer"}
                multiline
                maxRows={16}
                // focused="true"
                value={ga4Agreement.footer}
                onChange={(e)=>setGa4Agreement({...ga4Agreement,footer:e.target.value})}
            />
            <TextField
                sx={{
                    marginTop: '30px',
                    width: '100%'
                }}
                id="outlined-textarea"
                label={"Header"}
                multiline
                maxRows={16}
                // focused="true"
                value={ga4Agreement.header}
                onChange={(e)=>setGa4Agreement({...ga4Agreement,header:e.target.value})}
            />

            <Button type="submit" sx={{ margin: "10px 0px", width: '100%' }} variant="contained" onClick={(e) => create(e)}>Create Ga4 Agreement</Button>
            <Button type="submit" sx={{ margin: "10px 0px", width: '100%' }} variant="contained" onClick={(e) => update(e)}>Update Ga4 Agreement</Button>
        </form>
    </>)
}

export default Ga4Agreement;