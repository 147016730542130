import * as ActionConstants from "../constants/redux-constants";
import CdPocService from "../services/cd-poc-service";
import { showNotification } from "../../utilities/common-utility-functions";
import { cwMessages } from "../../constants/message-constants";
import { notificationType } from "../../constants/color-constant";

export const getCDPoCListing = () => async (dispatch) => {
  try {
    const res = await CdPocService.getAllCDPocServices();

    let cdPocListing = res?.data?.data;
    cdPocListing = cdPocListing?.map((e) =>
      Object.assign({}, e, { id: e._id })
    );
    dispatch({
      type: ActionConstants.GET_ALL_CD_POCS,
      payload: cdPocListing,
    });
  } catch (err) {
    showNotification(cwMessages.COMMON_ERROR_MESSAGE, notificationType.ERROR);
  }
};

export const addCDPoc = (dataObject) => async (dispatch) => {
  try {
    const res = await CdPocService.addCDPoC(dataObject);

    if (res.status === 200) {
      await dispatch(getCDPoCListing());
      showNotification(res.data.message, notificationType.SUCCESS);
    } else {
      showNotification(cwMessages.COMMON_ERROR_MESSAGE, notificationType.ERROR);
    }
  } catch (error) {
    showNotification(error?.response?.data.message, notificationType.ERROR);
  }
};

export const approveOrReject = (id, dataObject) => async (dispatch) => {
  try {
    const res = await CdPocService.approveOrReject(id, dataObject);

    if (res.status === 200) {
      await dispatch(getCDPoCListing());
      showNotification(res.data.message, notificationType.SUCCESS);
    } else {
      showNotification(cwMessages.COMMON_ERROR_MESSAGE, notificationType.ERROR);
    }
  } catch (error) {
    showNotification(error?.response?.data.message, notificationType.ERROR);
  }
};
