import * as ActionConstants from "../constants/redux-constants";
import UserDataService from "../services/user-data-service";
import { showNotification } from "../../utilities/common-utility-functions";
import { notificationType } from "../../constants/color-constant";
import { cwMessages } from "../../constants/message-constants";

export const getUserList = () => async (dispatch) => {
  try {
    const res = await UserDataService.getAllUsers();

    let userList = res?.data?.data;
    userList = userList?.map((e) => Object.assign({}, e, { id: e._id }));
    dispatch({
      type: ActionConstants.GET_ALL_USERS,
      payload: userList,
    });
  } catch (err) {
    showNotification(cwMessages.COMMON_ERROR_MESSAGE, notificationType.ERROR);
  }
};

export const addUser = (dataObject) => async (dispatch) => {
  try {
    const res = await UserDataService.addUser(dataObject);

    if (res.status === 200) {
      await dispatch(getUserList());
      showNotification(res.data.message, notificationType.SUCCESS);
    } else {
      showNotification(cwMessages.COMMON_ERROR_MESSAGE, notificationType.ERROR);
    }
  } catch (error) {
    showNotification(error?.response?.data.message, notificationType.ERROR);
  }
};
