import * as ActionConstants from "../constants/redux-constants";

const cdPocReducer = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionConstants.GET_ALL_CD_POCS:
      return payload;
  }
  return state;
};

export default cdPocReducer;
