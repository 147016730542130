import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ClickWrapButton from "./click-wrap-button";
import { Grid } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function ClickWrapDialog({
  component,
  dialogTitle,
  isOpenDialog,
  onCloseModal,
  onSaveModal,
  isWithoutSaveButton,
  isWithoutClose,
  isWithoutCancelButton,
  loadedAPIData,
  isShowServiceButton = true,
}) {
  const onClickDownloadButton = () => {
    window.open(loadedAPIData?.sample_contract, "_blank");
  };
  return (
    <BootstrapDialog
      disableEscapeKeyDown
      maxWidth={"lg"}
      fullWidth
      aria-labelledby="customized-dialog-title"
      open={isOpenDialog}
    >
      <Grid container alignItems={"center"} justifyContent={"space-between"}>
        <Grid item>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={!isWithoutClose && onCloseModal}
          >
            {dialogTitle}
          </BootstrapDialogTitle>
        </Grid>
        {isShowServiceButton && (
          <Grid style={{ marginRight: 10 }} item>
            <ClickWrapButton
              size={"small"}
              startIcon={<CloudDownloadIcon />}
              onClick={onClickDownloadButton}
              buttonText={"Agreement"}
            />
          </Grid>
        )}
      </Grid>
      <DialogContent style={{ overflowX: "hidden" }} dividers>
        {component}
      </DialogContent>
      {!isWithoutCancelButton && !isWithoutSaveButton && (
        <DialogActions>
          {!isWithoutCancelButton && (
            <ClickWrapButton
              buttonText={"Cancel"}
              variant={"outlined"}
              onClick={onCloseModal}
            />
          )}
          {!isWithoutSaveButton && (
            <ClickWrapButton
              buttonText={"Save"}
              variant={"contained"}
              onClick={onSaveModal}
            />
          )}
        </DialogActions>
      )}
    </BootstrapDialog>
  );
}

ClickWrapDialog.propTypes = {
  dialogTitle: PropTypes.string.isRequired,
  component: PropTypes.element.isRequired,
  isOpenDialog: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onSaveModal: PropTypes.func,
  isWithoutSaveButton: PropTypes.bool,
  isWithoutClose: PropTypes.bool,
  isWithoutCancelButton: PropTypes.bool,
  loadedAPIData: PropTypes.object,
  isShowServiceButton: PropTypes.bool,
};

export default ClickWrapDialog;
