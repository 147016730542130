import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ClickWrapButton from "./click-wrap-button";
import { Grid, TextField } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import axios from "axios";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function ClickWrapApproveRejectDialog({
  component,
  dialogTitle,
  isOpenDialog,
  onCloseModal,
  onClickApprove,
  onClickReject,
  onClickSendEnvelope,
  onClickClientSign,
  isWithoutClose,
  loadedAPIData,
  isShowServiceButton = true,
  approveOrRejectReason,
  onChangeApproveOrReject,
  selectedDataObject,
}) {
  const onClickDownloadButton = () => {
    window.open(loadedAPIData?.sample_contract, "_blank");
  };

  const checkCondition = selectedDataObject?.status_history
    ?.map((e) => e.status)
    .includes("DATA_SUBMITTED");

  const checkConditionSendEnv = selectedDataObject?.status_history
    ?.map((e) => e.status)
    .includes("DATA_SUBMITTED");
  const [loginUserRole, setLoginUserRole] = React.useState()
  // console.log();
  React.useEffect(() => {
    return (() => {
      setLoginUserRole(JSON.parse(localStorage.getItem('currentUserInfo'))?.role)
    })
  })
  return (
    <BootstrapDialog
      disableEscapeKeyDown
      maxWidth={"md"}
      fullWidth
      aria-labelledby="customized-dialog-title"
      open={isOpenDialog}
    >
      <Grid container alignItems={"center"} justifyContent={"space-between"}>
        <Grid item>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={!isWithoutClose && onCloseModal}
          >
            {dialogTitle}
          </BootstrapDialogTitle>
        </Grid>
        {isShowServiceButton && (
          <Grid style={{ marginRight: 10 }} item>
            <ClickWrapButton
              size={"small"}
              startIcon={<CloudDownloadIcon />}
              onClick={onClickDownloadButton}
              buttonText={"Agreement"}
            />
          </Grid>
        )}
      </Grid>
      <DialogContent style={{ overflowX: "hidden" }} dividers>
        {component}
        {checkCondition && (
          <TextField
            onChange={onChangeApproveOrReject}
            value={approveOrRejectReason}
            fullWidth
            multiline
            rows={4}
          />
        )}
      </DialogContent>

      {checkCondition && (
        <DialogActions>
          <ClickWrapButton
            buttonText={"Reject"}
            variant={"outlined"}
            onClick={onClickReject}
            style={selectedDataObject.status == 'DATA_SUBMITTED' && loginUserRole == 'FINANCE_TEAM' ? { display: 'block' } : { display: 'none' }}

          />

          <ClickWrapButton
            buttonText={"Approve"}
            variant={"contained"}
            onClick={onClickApprove}
            style={selectedDataObject.status == 'DATA_SUBMITTED' && loginUserRole == 'FINANCE_TEAM' ? { display: 'block' } : { display: 'none' }}

          />
        </DialogActions>
      )}
      {checkConditionSendEnv && (
        <DialogActions>

          <ClickWrapButton
            buttonText={"Approve and Send Envelope"}
            variant={"contained"}
            onClick={onClickSendEnvelope}
            style={selectedDataObject.status == 'FINANCE_VERIFIED' && loginUserRole == 'LEGAL_TEAM' ? { display: 'block' } : { display: 'none' }}
          />
        </DialogActions>
      )}
      <DialogActions>

        <ClickWrapButton
          buttonText={"Mark as client sign"}
          variant={"contained"}
          onClick={onClickClientSign}
          style={selectedDataObject.status == 'LEGAL_VERIFIED' ? { display: 'block' } : { display: 'none' }}
        />
      </DialogActions>
    </BootstrapDialog>
  );
}

ClickWrapApproveRejectDialog.propTypes = {
  dialogTitle: PropTypes.string.isRequired,
  component: PropTypes.element.isRequired,
  isOpenDialog: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  isWithoutClose: PropTypes.bool,
  loadedAPIData: PropTypes.object,
  isShowServiceButton: PropTypes.bool,
  approveOrRejectReason: PropTypes.string,
  onChangeApproveOrReject: PropTypes.func,
  onClickApprove: PropTypes.func,
  onClickReject: PropTypes.func,
  selectedDataObject: PropTypes.object,
  onClickSendEnvelope: PropTypes.func
};

export default ClickWrapApproveRejectDialog;
