import React from "react";
import { Grid } from "@mui/material";
import ClickWrapHeading from "./click-wrap-heading";
import ClickWrapButton from "./click-wrap-button";
import PropTypes from "prop-types";
import AddIcon from "@mui/icons-material/Add";

function ClickWrapHeadRow({ rowHeading, buttonText, onClickButton }) {
  return (
    <Grid
      container
      justifyContent={"space-between"}
      alignItems={"center"}
      direction={"row"}
    >
      <Grid item>
        <ClickWrapHeading heading={rowHeading} />
      </Grid>

      <Grid item>
        <ClickWrapButton
          size={"small"}
          startIcon={<AddIcon />}
          variant={"contained"}
          buttonText={buttonText}
          onClick={onClickButton}
        />
      </Grid>
    </Grid>
  );
}

ClickWrapHeadRow.propTypes = {
  rowHeading: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onClickButton: PropTypes.func.isRequired,
};
export default ClickWrapHeadRow;
