import React from "react";
import { FormControl, MenuItem, OutlinedInput, Select } from "@mui/material";
import PropTypes from "prop-types";

function ClickWrapDropDown({
  allOptions = [],
  selectedValue = "",
  onChange,
  name,
  disabledOptionCaption,
}) {
  return (
    <FormControl sx={{ width: "100%" }}>
      <Select
        inputProps={{ "aria-label": "Without label" }}
        size={"small"}
        displayEmpty
        value={selectedValue}
        onChange={onChange}
        name={name}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em>{disabledOptionCaption}</em>;
          }
          const a = allOptions.filter((e) => e.label === selected);
          return a[0].name;
        }}
        input={<OutlinedInput />}
        id="entity-type-simple-select"
      >
        {disabledOptionCaption && (
          <MenuItem disabled value="">
            <em>{disabledOptionCaption}</em>
          </MenuItem>
        )}
        {allOptions.map((element) => {
          return (
            <MenuItem key={element.label} value={element.label}>
              {element.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

ClickWrapDropDown.propTypes = {
  allOptions: PropTypes.array.isRequired,
  selectedValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  disabledOptionCaption: PropTypes.string,
};

export default ClickWrapDropDown;
