import * as ActionConstants from "../constants/redux-constants";
import { showNotification } from "../../utilities/common-utility-functions";
import { cwMessages } from "../../constants/message-constants";
import { notificationType } from "../../constants/color-constant";

export const setCurrentUser = (data) => async (dispatch) => {
  try {
    dispatch({
      type: ActionConstants.CURRENT_USER,
      payload: data,
    });
    localStorage.setItem("currentUserInfo", JSON.stringify(data));
  } catch (err) {
    showNotification(cwMessages.COMMON_ERROR_MESSAGE, notificationType.ERROR);
  }
};
