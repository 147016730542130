import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import {
  cdPOCMapping,
  clickWrapClientMapping,
} from "../../constants/data-mapping";
import { useDispatch, useSelector } from "react-redux";
import {
  clickWrapUserRole,
  CW_TYPES_OF_SERVICES_DATA_MAPPING,
} from "../../constants/click-wrap-constants";
import { getClientSignedInformation,getClickWrapDataFromCDPOc } from "../../redux/actions/click-wrap-actions";
import { Step, StepLabel, Stepper, Typography } from "@mui/material";
import {
  base64toBlobURL,
  dateFormatter,
  openSOWURL
} from "../../utilities/common-utility-functions";
import ClickWrapButton from "../../components/click-wrap-button";

function CustomerFormStaticTable({ dataObject }) {
  const loggedUserData = useSelector((state) => state.loggedUser);
  const dispatch = useDispatch();
  const [clientInformation, setClientInformation] = useState({});

  useEffect(() => {
    if (
      loggedUserData.role === clickWrapUserRole.LEGAL_TEAM ||
      clickWrapUserRole.FINANCE_TEAM ||
      clickWrapUserRole.SUPER_ADMIN
    ) {
      dispatch(getClickWrapDataFromCDPOc(dataObject._id)).then((response) => {
        console.log("getClickWrapDataFromCDPOc", response)
        localStorage.setItem(
          "email",
          response.data.data[0].emailIdOfAuthorizedSignatory,
        );
      })
      dispatch(getClientSignedInformation(dataObject._id)).then((response) => {
        localStorage.setItem("typesOfServices",dataObject.typesOfServices);
        localStorage.setItem("selectedTatvicEntity",dataObject.selectedTatvicEntity);
        let responseData = response?.data?.data[0];
        localStorage.setItem("clickWrapTempId", response?.data?.data[0]._id);
        localStorage.setItem("clickWrapID", responseData._id);
        localStorage.setItem("name", dataObject.customerName);
        localStorage.setItem("filePDF", responseData.unsigned_pdf);

        setClientInformation(response?.data?.data[0]);
      });
    }
  }, []);

  const getTimeLine = () => {
    return (
      <Stepper
        activeStep={dataObject?.status_history?.length}
        sx={{ m: 1, overflowX: "scroll", overflowY: "hidden" }}
        alternativeLabel>
        {dataObject?.status_history?.map((element) => {
          console.log(element)
          return (
            <Step key={element.status}>
              <StepLabel>
                <Typography fontSize={12}>{element.status}</Typography>
                <Typography fontSize={10}>
                  {dateFormatter(element.updatedAt)}
                </Typography>
                <Typography sx={{ color: "#3d3d3d" }} fontSize={10}>
                  {element.comment.toString().length > 0 &&
                    "Comment: " + element.comment}
                </Typography>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    );
  };

  if (
    !dataObject ||
    !clientInformation ||
    Object.keys(dataObject).length === 0 ||
    Object.keys(clientInformation).length === 0
  ) {
    return <h3>Data is loading..</h3>;
  }

  return (
    <React.Fragment>
      {getTimeLine()}
      <TableContainer>
        <Typography variant={"h5"}>CD POC Information</Typography>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Parameter</TableCell>
              <TableCell align='right'>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(dataObject)
              .filter((e) => (e[0] !== "status_history" && e[1] !== "NA"))
              .map((row) => {
                console.log(row)
                if (!row[1]) {
                  return null;
                }
                return (
                  <TableRow
                    key={row[0]}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component='th' scope='row'>
                      {cdPOCMapping[row[0]]}
                    </TableCell>
                    <TableCell
                      style={{
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                      }}
                      align='right'>
                      {row[0] === "typesOfServices"
                        ? CW_TYPES_OF_SERVICES_DATA_MAPPING[row[1]]
                        : row[0]== "createdBy" ? `${row[1]?.firstName} ${row[1]?.lastName}`
                        : row[0]=="statementOfWorkLink"?<ClickWrapButton
                        onClick={() => base64toBlobURL(row[1])}
                        buttonText={"SOW Link"}
                      />
                        :row[1]}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>

        <Typography variant={"h5"}>Client Information</Typography>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Parameter</TableCell>
              <TableCell align='right'>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(clientInformation).map((row) => {
              if (!row[1]) {
                return null;
              }
              return (
                <TableRow
                  key={row[0]}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell component='th' scope='row'>
                    {clickWrapClientMapping[row[0]]}
                  </TableCell>
                  <TableCell
                    style={{
                      whiteSpace: "normal",
                      wordBreak: "break-word",
                    }}
                    align='right'>
                    {row[0] === "unsigned_pdf" ||
                    row[0] === "signed_pdf" ||
                    row[0] === "authorizedSignatoryDocument" ||
                    row[0] === "govtApprovedIDProofDocument" ? (
                      <ClickWrapButton
                        onClick={() => base64toBlobURL(row[1])}
                        buttonText={"Download"}
                      />
                    ) : row[0] === "additionalSupportServices" ? (
                      row[1].join(", ")
                    ) : (
                      row[1]
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

CustomerFormStaticTable.propTypes = {
  dataObject: PropTypes.object,
};

export default CustomerFormStaticTable;
