export const APP_COLORS = {
  BACKGROUND_COLOR: "#EFEFEF",
};

export const notificationType = {
  SUCCESS: "success",
  ERROR: "error",
};

export const chipTypeBasedOnRole = {
  SUPER_ADMIN: "success",
  LEGAL_TEAM: "warning",
  FINANCE_TEAM: "info",
  CD_TEAM: "error",
  AM_TEAM: "secondary"

};
