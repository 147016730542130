import AxiosInstance from "../AxiosInstance";

class UserDataService {
  getAllUsers() {
    return AxiosInstance.get("/api/v1/users");
  }

  addUser(userData) {
    return AxiosInstance.post("/api/v1/users", userData);
  }

  loginUser(data) {
    return AxiosInstance.post("/api/v1/users/login", data);
  }
}

export default new UserDataService();
